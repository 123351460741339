import { Button } from "antd";
import styles from './compare.module.css';
import { useDispatch, useSelector } from "react-redux";
import { selectIsComparing, toggleCompare } from "../slice";
import { getAnalytics } from "../Compare/slice";
import { useTranslation } from "react-i18next";

export default function CompareButton () {
  const dispatch = useDispatch();
  const isComparing = useSelector(selectIsComparing);
  const { t } = useTranslation();
  const text = isComparing ? t("analytics.buttons.close") : t("analytics.buttons.compare");

  const handleCompare = () => {
    if (!isComparing) {
      dispatch(getAnalytics());
    }

    dispatch(toggleCompare());
  };

  return <Button onClick={handleCompare} className={styles.btnCompare}>{text}</Button>
}