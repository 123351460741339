import CompareButton from "../Compare/CompareButton";
import CallTypeFilter from "./CallTypeFilter";
import DateFilter from "./DateFilter";
import InteractionCallTypesFilter from "./InteractionCallTypesFilter";
import UserFilter from "./UserFilter";
import TeamFilter from "./TeamFilter";

export default function AnalyticsFilter(
  { isSecondary, isComparing } = { isSecondary: false }
) {
  return (
    <>
      <InteractionCallTypesFilter isSecondary={isSecondary} />
      <CallTypeFilter isSecondary={isSecondary} />
      <DateFilter isSecondary={isSecondary} />
      <UserFilter isSecondary={isSecondary} />
      {/* <TeamFilter isSecondary={isSecondary}/> */}
      {!isSecondary && !isComparing ? <CompareButton /> : ""}
      {isSecondary && isComparing ? <CompareButton /> : ""}
    </>
  );
}
