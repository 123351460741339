import { Layout, Menu, Spin } from "antd";
import {
  LoadingOutlined,
  FileWordFilled,
  AimOutlined,
  TrophyOutlined,
  CalendarOutlined,
  SoundOutlined,
  ClockCircleOutlined,
  MonitorOutlined,
  FolderAddOutlined,
  EyeOutlined,
  DashboardOutlined,
  EditOutlined,
  TagsOutlined,
  TeamOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import HostsFilter from "./filters/hosts/HostsFilter";
import TeamsFilter from "./filters/teams/Filter";
import SourceFilter from "./filters/source/SourceFilter";
import WordsFilter from "./filters/words/WordsFilter";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getHosts,
  isLoading,
  searchMeetings,
  selectAppFilters,
  selectIsMeetingListReady,
  selectedFilters,
} from "./filters/slice";
import __ from "../../app/i18n";
import ClientFilter from "./filters/client/ClientFilter";
import SamuScoreFilter from "./filters/samuScore/Index";
import SpeakerFilter from "./filters/speaker/SpeakerFilter";
import DateFilter from "./filters/date/DateFilter";
import DurationFilter from "./filters/duration/DurationFilter";
import SeenFilter from "./filters/seen/SeenFilter";
import { loadClients, loadSpeakers, loadTrackers } from "../team/users/slice";
import TrackerFilter from "./filters/tracker/TrackerFilter";
import LibraryFilter from "./filters/library/LibraryFilter";
import ClearFilters from "./ClearFilters";
import CallTypeSelector from "./filters/calltypes/CallTypeSelector";
import SaveCallType from "./filters/calltypes/SaveCallType";
import { loadCallTypes } from "./filters/calltypes/slice";
import NameFilter from "./filters/name/NameFilter";
import TagFilter from "./filters/tags/TagFilter";
import HubspotFilter from "../marketplace/Hubspot/Filter";
import MarketplaceFilter from "../marketplace/Marketplace/Filter";
import ExtractorFilter from "../marketplace/Extractor/Filter";
import InteractionCallTypesFilter from "./filters/interactionCallTypes/Filter";
import { Broadcast } from "tabler-icons-react";
import { loadTeams } from "../team/teams/slice";
import ConferenceIdFilter from './filters/conferenceId/ConferenceIdFilter';
import { useTranslation } from "react-i18next";
import { loadInteractionCallTypes } from "../team/interactionCallType/slice";
import { IconPhone } from '@tabler/icons-react';

const { SubMenu } = Menu;
const { Sider } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function ListFilters() {
  const ready = useSelector(selectIsMeetingListReady);
  const isFetching = useSelector(isLoading);
  const appliedFilters = useSelector(selectedFilters);
  const dispatch = useDispatch();
  const rootSubmenuKeys = ["hosts", "words"];
  const [openKeys, setOpenKeys] = useState([...rootSubmenuKeys]);
  const appFilters = useSelector(selectAppFilters);
  const { t } = useTranslation();

  const getAppByTypeId = (typeId, props) => {
    const map = {
      "65b05066b9d8ab7dca53b669": <HubspotFilter {...props} />,
      "65b0500ab9d8ab7dca53b667": <ExtractorFilter {...props} />,
    };

    const component = map[typeId];
    if (!component) {
      return <MarketplaceFilter {...props} />;
    }

    return map[typeId];
  };

  useEffect(() => {
    const applied = [];
    if (appliedFilters.hosts.length) {
      applied.push("submenu_hosts");
    }

    if (appliedFilters.clients.length) {
      applied.push("submenu_client");
    }

    if (appliedFilters.speakers.length) {
      applied.push("submenu_speaker");
    }

    if (appliedFilters.words) {
      applied.push("submenu_words");
    }

    if (appliedFilters.trackers.length) {
      applied.push("submenu_trackers");
    }

    if (appliedFilters.dateTo) {
      applied.push("submenu_date");
    }

    if (appliedFilters.seen !== null) {
      applied.push("submenu_seen");
    }

    if (
      appliedFilters.duration &&
      (appliedFilters.duration.from || appliedFilters.duration.to) &&
      appliedFilters.duration.applied
    ) {
      applied.push("submenu_duration");
    }

    if (appliedFilters.libraries.length) {
      applied.push("submenu_libraries");
    }

    setOpenKeys([...openKeys, ...applied]);
  }, [appliedFilters]);

  const getAppliedStyles = (item) => {
    let isSelected =
      appliedFilters &&
      (appliedFilters[item] ||
        (item === "seen" && appliedFilters[item] !== null)) &&
      (!Array.isArray(appliedFilters[item]) ||
        (Array.isArray(appliedFilters[item]) && appliedFilters[item].length)) &&
      (item !== "duration" ||
        (item === "duration" && appliedFilters[item].applied)) &&
      (item !== "samuScore" ||
        (item === "samuScore" && appliedFilters[item].applied));

    if (String(item).includes("appFilters")) {
      const parts = item.split(".");

      if (
        parts.length === 2 &&
        appliedFilters &&
        appliedFilters[parts[0]] &&
        appliedFilters[parts[0]][parts[1]] &&
        Object.values(appliedFilters[parts[0]][parts[1]]).length
      ) {
        isSelected = true;
      }
    }

    if (isSelected) {
      return {
        border: "2px solid #ccc",
        color: "#1791ff",
        fontWeight: "600",
        background: "#eee",
        borderRadius: "5px",
      };
    }
  };

  useEffect(() => {
    dispatch(loadCallTypes());
    dispatch(getHosts());
    dispatch(searchMeetings());
    dispatch(loadTeams());
    dispatch(loadInteractionCallTypes())

    /** Load clients */
    dispatch(loadClients());
    dispatch(loadSpeakers());
    dispatch(loadTrackers());
  }, []);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Sider
      className={isFetching ? "loading-box" : ""}
      style={{ background: "#eff2f5" }}
      width={400}
    >
      {isFetching ? (
        <Spin indicator={antIcon} />
      ) : (
        <>
          <CallTypeSelector />
          <ClearFilters />
          <SaveCallType />
          {/* <SavedFilters /> */}
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            style={{ width: 400 }}
            disabled={!ready}
          >
            <SubMenu
              className="filter filters-name"
              key="submenu_name"
              icon={<EditOutlined />}
              style={getAppliedStyles("name")}
              title={__("meetings.filters.name")}
            >
              <NameFilter />
            </SubMenu>
            <SubMenu
              className="filter filters-conference"
              key="submenu_conference"
              icon={<LinkOutlined />}
              style={getAppliedStyles("conferenceId")}
              title={__("meetings.filters.meetingLink")}
            >
              <ConferenceIdFilter />
            </SubMenu>
            <SubMenu
              className="filter filters-source"
              key="submenu_source"
              icon={<Broadcast width={'16px'} />}
              style={getAppliedStyles("sources")}
              title={__("meetings.filters.source")}
            >
              <SourceFilter />
            </SubMenu>
            <SubMenu
              className="filter filters-hosts"
              key="submenu_hosts"
              icon={<TrophyOutlined />}
              style={getAppliedStyles("hosts")}
              title={__("meetings.filters.host")}
            >
              <HostsFilter />
            </SubMenu>
            <SubMenu
              className="filter filters-teams"
              key="submenu_teams"
              icon={<TeamOutlined />}
              style={getAppliedStyles("teams")}
              title={__("team.teams")}
            >
              <TeamsFilter />
            </SubMenu>

            <SubMenu
              className="filter filters-interactionCallTypes"
              key="submenu_interactionCallTypes"
              icon={<IconPhone size="14px" />}
              style={getAppliedStyles("interactionCallTypes")}
              title={__("team.callTypes.label")}
            >
              <InteractionCallTypesFilter />
            </SubMenu>

            <SubMenu
              className="filter filters-clients"
              key="submenu_client"
              icon={<AimOutlined />}
              title={__("meetings.filters.client")}
              style={getAppliedStyles("clients")}
            >
              <ClientFilter />
            </SubMenu>
            
            {appFilters.map((appFilter, i) => (
              <SubMenu
                key={"submenu_app_" + i}
                icon={
                  <img
                    style={{ width: "14px", marginRight: "0" }}
                    src={appFilter.icon}
                  />
                }
                style={{
                  ...getAppliedStyles("appFilters." + appFilter.id),
                  color: "#000000d9",
                  paxddingLeft: "24px",
                }}
                title={t(appFilter.name)}
                className={"filter filters-app " + appFilter.id}
              >
                {getAppByTypeId(appFilter.type, appFilter)}
              </SubMenu>
            ))}

            <SubMenu
              className="filter filters-samu-score"
              key="submenu_samu_score"
              icon={<DashboardOutlined />}
              title={__("meetings.filters.samuScore.label")}
              style={getAppliedStyles("samuScore")}
            >
              <SamuScoreFilter />
            </SubMenu>
            <SubMenu
              className="filter filters-libraries"
              key="submenu_libraries"
              icon={<FolderAddOutlined />}
              style={getAppliedStyles("libraries")}
              title={__("meetings.filters.libraries")}
            >
              <LibraryFilter />
            </SubMenu>
            <SubMenu
              className="filter filters-tags"
              key="submenu_tags"
              icon={<TagsOutlined />}
              style={getAppliedStyles("tags")}
              title={__("meetings.filters.tags")}
            >
              <TagFilter />
            </SubMenu>
            <SubMenu
              className="filter filters-trackers"
              key="submenu_trackers"
              icon={<MonitorOutlined />}
              style={getAppliedStyles("trackers")}
              title={__("meetings.filters.trackers")}
            >
              <TrackerFilter />
            </SubMenu>
            <SubMenu
              className="filter filters-speakers"
              key="submenu_speaker"
              icon={<SoundOutlined />}
              style={getAppliedStyles("speakers")}
              title={__("meetings.filters.speakers")}
            >
              <SpeakerFilter />
            </SubMenu>
            <SubMenu
              className="filter filters-duration"
              key="submenu_duration"
              icon={<ClockCircleOutlined />}
              style={getAppliedStyles("duration")}
              title={__("meetings.filters.duration.label")}
            >
              <DurationFilter />
            </SubMenu>
            <SubMenu
              className="filter filters-seen"
              key="submenu_seen"
              icon={<EyeOutlined />}
              style={getAppliedStyles("seen")}
              title={__("meetings.filters.seen")}
            >
              <SeenFilter />
            </SubMenu>
            <SubMenu
              className="filter filters-date"
              key="submenu_date"
              icon={<CalendarOutlined />}
              style={getAppliedStyles("dateFrom")}
              title={__("meetings.filters.date")}
            >
              <DateFilter />
            </SubMenu>
            <SubMenu
              key="submenu_words"
              icon={<FileWordFilled />}
              style={getAppliedStyles("words")}
              title={__("meetings.filters.words")}
              className="filter filters-words"
            >
              <WordsFilter />
            </SubMenu>
          </Menu>
        </>
      )}
    </Sider>
  );
}
