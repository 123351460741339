import AnalyticBar from "./AnalyticBar";
import { Typography } from "antd";
import AnalyticsTabContent from "./AnalyticsTabContent";
import PeriodGraphs from "./PeriodGraphs";
import { useTranslation } from "react-i18next";

export default function CallDurationTab({ analyticsState }) {
  const {t} = useTranslation();
  return (
    <AnalyticsTabContent>
      <div>
        <div>
          <Typography.Title
            className={"analyticsTabTitle"}
            level={3}
            style={{ marginBottom: "15px" }}
          >
            {t("analytics.dashboard.tabs.activity.children.callDuration.title")}
          </Typography.Title>
          <AnalyticBar
            className={"dtabContent2Items"}
            userAnalytics={analyticsState.callDuration.durations}
            itemKeyName={"Total del usuario"}
            prop={'avg'}
            avg={analyticsState.callDuration.avg}
            useTimeFormatter={true}
          />
        </div>
        <PeriodGraphs title={t("analytics.dashboard.tabs.activity.children.callDuration.evolution")} userAnalytics={analyticsState.callDuration.periods}/>
      </div>
    </AnalyticsTabContent>
  );
}
