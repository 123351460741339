import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "antd/lib/layout/layout";
import { Layout } from "antd";
import CoachingFilters from "./filters/CoachingFilters";
import CoachingTable from "./table";
import {
  getCoachingData,
  selectDateFilter,
  selectCallTypeId,
  selectTeamId,
} from "./slice";
import TabInformation from "./TabInformation";
import styles from "./coaching.module.css";

export default function CoachingPage() {
  const dispatch = useDispatch();
  const dateFilter = useSelector(selectDateFilter);
  const callTypeId = useSelector(selectCallTypeId);
  const teamId = useSelector(selectTeamId);

  useEffect(() => {
    dispatch(getCoachingData());
  }, [dispatch, dateFilter, callTypeId, teamId]);

  return (
    <Content style={{ padding: "0" }}>
      <Layout className="site-layout-background main-layout-container">
        <Content style={{ padding: "24px", minHeight: 280 }}>
          <TabInformation />
          <div>
            <CoachingFilters />
          </div>
          <div className={styles.divider} />
          <CoachingTable />
        </Content>
      </Layout>
    </Content>
  );
}
