import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCallTypes, selectCallTypesFetched } from "../../meetings/filters/calltypes/slice";
import { Select } from "antd";
import { removeAccents } from "../../../app/utils";
import __ from "../../../app/i18n";
import * as primarySlice from "../slice";
import * as secondarySlice from "../Compare/slice";
import styles from '../analytics.module.css';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import 'moment/locale/pt';

export default function DateFilter ({ isSecondary } = { isSecondary: false }) {
  const { updateAnalyticsDateFilters, selectAccountCreatedAt } = isSecondary ? secondarySlice : primarySlice;

  const dispatch = useDispatch();
  const accountCreatedAt = useSelector(selectAccountCreatedAt);
  const callTypeFetched = useSelector(selectCallTypesFetched);
  const [value, setValue] = useState();
  const [options, setOptions] = useState();
  const [dateFiltersMap, setDateFiltersMap] = useState();
  const { t , i18n } = useTranslation();

  useEffect(() => {
    if (!callTypeFetched) {
      dispatch(loadCallTypes());
    }
  }, [callTypeFetched]);

  useEffect(() => {
    const newDateFiltersMap = new Map();
    let newMonthOptions = [];
    const monthFrom = moment(accountCreatedAt).set('hour', 0).set('minute', 0);

    while (moment().diff(monthFrom, 'month') >= 0)  {
      const month = monthFrom.month();
      const year = monthFrom.year();
      const monthlyKey = [month, year].join('/');
      const date = moment().locale(i18n.language).set({ month, year }).set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });

      if (date.isSameOrBefore(moment())) {
        const label = date.format("MMMM")[0].toUpperCase() + date.format("MMMM").substring(1, date.format("MMMM").length) + ' ' + date.year();
        newMonthOptions.push({ key: 'dates#' + monthlyKey, label, value: monthlyKey });
  
        const from = moment(date);
        const to = moment(date).add(1, 'month');
  
        newDateFiltersMap.set(monthlyKey, { from, to });
      }

      monthFrom.add(1, 'month');
    }

    newMonthOptions = [...newMonthOptions.reverse()];

    let newPeriodOptions = [];
    // Ultimos 90 dias.
    const last90daysFrom = moment().subtract(90, 'days');
    newPeriodOptions.push({ key: 'dates#90days', label: t("analytics.filters.date.options.last90Days"), value: 'last90Days' });
    newDateFiltersMap.set('last90Days', { from: last90daysFrom, to: moment() });
  
    // Ultimos 30 dias.
    const last30daysFrom = moment().subtract(30, 'days');
    newPeriodOptions.push({ key: 'dates#30days', label: t("analytics.filters.date.options.last30Days"), value: 'last30Days' });
    newDateFiltersMap.set('last30Days', { from: last30daysFrom, to: moment() });

    // Ultimos 15 dias.
    const last15daysFrom = moment().subtract(15, 'days');
    newPeriodOptions.push({ key: 'dates#15Days', label: t("analytics.filters.date.options.last15Days"), value: 'last15Days' });
    newDateFiltersMap.set('last15Days', { from: last15daysFrom, to: moment() });

    // Ultimos 7 dias.
    const lastWeekFrom = moment().subtract(7, 'days');
    newPeriodOptions.push({ key: 'dates#7days', label: t("analytics.filters.date.options.last7Days"), value: 'last7Days' });
    newDateFiltersMap.set('last7Days', { from: lastWeekFrom, to: moment() });

    newPeriodOptions = [...newPeriodOptions.reverse()];

    const options = [
      {
        label: 'Periodos',
        options: newPeriodOptions,
      },
      {
        label: 'Meses',
        options: newMonthOptions,
      },
    ];

    setOptions(options);
    setDateFiltersMap(newDateFiltersMap);
  }, [accountCreatedAt])

  const handleOnChange = (selectedDate) => {
    let from;
    let to;
    if (selectedDate) {
      const dateFilters = dateFiltersMap.get(selectedDate);
      from = dateFilters.from.format('YYYY-MM-DD');
      to = dateFilters.to.format('YYYY-MM-DD');
    }

    setValue(selectedDate);
    dispatch(updateAnalyticsDateFilters({ from, to }));
  };

  const selectProps = {
    options,
    value: value || 'last7Days',
    onChange: (e) => handleOnChange(e),
    placeholder: __('meetings.filters.selectCallType'),
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = String(item.label).toUpperCase().startsWith(String(removeAccents(typed)).toUpperCase())
      return contains;
    },
    onClear: (e) => handleOnChange(e),
    className: styles.analyticsFilterSelect,
    allowClear: true
  };

  return <div className={styles.analyticsFilterSelectorContainer}>
    <span className={styles.analyticsFilterFilterTitle}>{t("analytics.filters.date.label")}</span>
    <Select {...selectProps}/>
  </div>
}