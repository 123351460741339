import { Button, Slider, Typography } from "antd";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import __ from "../../../../app/i18n";
import { selectedSamuScore, updateSamuScoreFilter } from "../slice";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

export default function SamuScoreFilter() {
  const [hasChanges, setHasChanges] = useState(false);
  const selectorSamuScore = useSelector(selectedSamuScore)
  let [changes, setChanges] = useState(selectorSamuScore);
  const samuScore = useSelector(selectedSamuScore);
  const { t } = useTranslation();
  const { from, to } = samuScore || {};
  const dispatch = useDispatch();
  const defaults = { from: 0, to: 10 };

  const handleClick = () => {
    dispatch(updateSamuScoreFilter(changes));
    setHasChanges(false)
  }
  const onChangeSamuScore = ([from, to]) => {
    setHasChanges(true)
    setChanges({ from, to });
  }

  useEffect(() => {
    setChanges(selectorSamuScore)
  }, [selectorSamuScore]);

  const currentFrom = Number.isInteger((changes || {}).from) ? (changes || {}).from : defaults.from;
  const currentTo = Number.isInteger((changes || {}).to) ? (changes || {}).to : defaults.to;

  return (
    <div style={{ margin: "15px 15px" }}>
      <Text type="secondary">{ t("meetings.filters.samuScore.range", { from: currentFrom, to: currentTo })}</Text>
      <Slider range defaultValue={[from || defaults.from, to || defaults.to]} max={10} onAfterChange={onChangeSamuScore} />
      { hasChanges ? <Button
        type="primary"
        style={{ marginTop: "5px", width: "100%" }}
        onClick={handleClick}
      >
        { __('meetings.filters.apply') }
      </Button> : '' }
      
    </div>
  );
}
