import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Space,
  Card,
  Select,
  Divider,
  Input,
  Spin,
  Row,
  Col,
  message,
  Alert,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import {
  getMeetingScores,
  testCustomScore,
  selectMeetings,
  selectTestResults,
  selectIsLoading,
  resetTestResults,
} from "../slice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
const { Text } = Typography;
const { Option } = Select;

const extractMeetingIdFromUrl = (url, t) => {
  try {
    const urlObj = new URL(url);
    const pathParts = urlObj.pathname.split("/");
    const meetingIndex = pathParts.indexOf("meeting");

    if (meetingIndex === -1 || !pathParts[meetingIndex + 1]) {
      message.error(t("apps.customScore.validation.noMeetingId"));
      return null;
    }

    const meetingId = pathParts[meetingIndex + 1];
    if (!/^[0-9a-fA-F]{24}$/.test(meetingId)) {
      message.error(t("apps.customScore.validation.invalidMeetingId"));
      return null;
    }

    return meetingId;
  } catch (error) {
    message.error(t("apps.customScore.validation.invalidLink"));
    return null;
  }
};

export const ScoreValidation = ({ scores, onSave, currentCallType }) => {
  const currentScore = scores.find((s) => s.callType === currentCallType);
  const dispatch = useDispatch();
  const meetings = useSelector(selectMeetings);
  const testResults = useSelector(selectTestResults);
  const isLoading = useSelector(selectIsLoading);

  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [isLinkInputVisible, setIsLinkInputVisible] = useState(false);
  const [meetingLink, setMeetingLink] = useState("");
  const [isMeetingValidationLoading, setIsMeetingValidationLoading] =
    useState(false);
  const { t } = useTranslation();

  const validateEvaluables = () => {
    if (!currentScore?.evaluables) return false;

    const hasValidEvaluables = currentScore.evaluables.every(
      (category) =>
        category.categoryName?.trim() &&
        category.items?.length > 0 &&
        category.items.every((item) => item.evaluable?.trim())
    );

    return hasValidEvaluables && currentScore.evaluables.length > 0;
  };

  const calculateTotalWeight = () => {
    if (!currentScore?.evaluables) return 0;

    const v = currentScore.evaluables.reduce((total, category) => {
      if (!category.categoryName?.trim()) return total;

      const categoryTotal =
        category.items?.reduce(
          (sum, item) => sum + (parseFloat(item.weight) || 0),
          0
        ) || 0;
      return total + categoryTotal;
    }, 0);

    if (v > 9.9) {
      return 10;
    }

    return v;
  };

  const hasValidEvaluables = validateEvaluables();
  const totalWeight = calculateTotalWeight();
  const isValid = totalWeight === 10 && hasValidEvaluables;

  useEffect(() => {
    if (currentCallType && currentCallType !== "Default") {
      dispatch(getMeetingScores(currentCallType));
    }
    setSelectedMeeting(null);
    setMeetingLink("");
    dispatch(resetTestResults());
  }, [currentCallType, dispatch]);

  const handleMeetingChange = async (meetingIdOrLink) => {
    const meetingId = isLinkInputVisible
      ? extractMeetingIdFromUrl(meetingIdOrLink, t)
      : meetingIdOrLink;

    if (!meetingId) {
      if (isLinkInputVisible) {
        setMeetingLink("");
      }
      return;
    }

    if (!currentCallType) return;

    setIsMeetingValidationLoading(true);
    setSelectedMeeting(meetingId);

    await dispatch(
      testCustomScore({
        id: meetingId,
        customScore: {
          callType: currentCallType,
          description: currentScore?.description,
          evaluables: currentScore?.evaluables,
        },
      })
    );
    setIsMeetingValidationLoading(false);
  };

  return (
    <Space
      direction="vertical"
      style={{ width: "100%", position: "sticky", top: 0 }}
    >
      <Card>
        <Space direction="vertical" style={{ width: "100%" }}>
          {totalWeight !== 10 && (
            <Alert
              message={t("apps.customScore.validation.invalidTotalWeight")}
              description={t("apps.customScore.validation.totalWeightMustBe10")}
              type="warning"
              showIcon
              style={{ marginBottom: "16px" }}
            />
          )}

          <Button
            disabled={!isValid}
            type="primary"
            onClick={() => onSave(currentScore)}
            block
          >
            {t("apps.customScore.validation.saveScore")}
          </Button>

          <Row justify="space-between" align="middle">
            <Col>
              <Text strong>
                {t("apps.customScore.validation.totalValue")}:
              </Text>
            </Col>
            <Col>
              <Text
                strong
                style={{
                  color: totalWeight === 10 ? "green" : "red",
                  fontSize: "16px",
                }}
              >
                {totalWeight.toFixed(1)}/10.0
              </Text>
            </Col>
          </Row>
        </Space>
      </Card>

      <Card>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text strong>
            {t("apps.customScore.validation.evaluateMeeting")}
          </Text>

          {isLinkInputVisible ? (
            <Input.Search
              placeholder={t("apps.customScore.validation.meetingPlaceholder")}
              value={meetingLink}
              onChange={(e) => setMeetingLink(e.target.value)}
              onSearch={handleMeetingChange}
              enterButton
            />
          ) : (
            <div style={{ display: "flex" }}>
              <Select
                style={{ width: "calc(100% - 4rem)" }}
                onChange={handleMeetingChange}
                placeholder={t("apps.customScore.validation.selectMeeting")}
                loading={isLoading}
                value={selectedMeeting}
              >
                {meetings.map((meeting) => (
                  <Option key={meeting._id} value={meeting._id}>
                    <span
                      style={{
                        background: "#b389ff",
                        borderRadius: "15px",
                        fontSize: "10px",
                        padding: "5px",
                        color: "#fff",
                        marginRight: "5px"
                      }}
                    >
                      {moment(meeting.dateFrom).format("DD/MM/YYYY")}
                    </span>
                    {meeting.name}
                  </Option>
                ))}
              </Select>
              <Button
                style={{ width: "4rem" }}
                onClick={() => handleMeetingChange(selectedMeeting)}
              >
                <SendOutlined />
              </Button>
            </div>
          )}

          <Button
            type="link"
            onClick={() => setIsLinkInputVisible(!isLinkInputVisible)}
            style={{ padding: 0 }}
          >
            {isLinkInputVisible
              ? t("apps.customScore.validation.searchInList")
              : t("apps.customScore.validation.enterMeetingLink")}
          </Button>

          {isMeetingValidationLoading ? (
            <Row justify="center">
              <Spin />
            </Row>
          ) : (
            testResults?.evaluables && (
              <Space direction="vertical" style={{ width: "100%" }}>
                {Object.values(testResults.evaluables).map((category) => (
                  <div key={category.categoryName}>
                    <Typography.Title level={5} style={{ marginBottom: "8px" }}>
                      {category.categoryName}
                    </Typography.Title>
                    <Space
                      direction="vertical"
                      style={{ width: "100%", marginBottom: "16px" }}
                    >
                      {category.items?.map((item) => (
                        <Row
                          key={item.evaluable}
                          justify="space-between"
                          wrap={false}
                        >
                          <Col
                            flex="auto"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.evaluable}
                          </Col>
                          <Col flex="none">
                            {item.value ? (
                              <CheckCircleOutlined
                                style={{ color: "green", marginLeft: "8px" }}
                              />
                            ) : (
                              <CloseCircleOutlined
                                style={{ color: "red", marginLeft: "8px" }}
                              />
                            )}
                          </Col>
                        </Row>
                      ))}
                    </Space>
                  </div>
                ))}
                <Divider />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text strong style={{ fontSize: "16px" }}>
                    {t("apps.customScore.validation.totalScore")}:
                  </Text>
                  <Text
                    strong
                    style={{
                      color:
                        testResults.score >= 10
                          ? "green"
                          : testResults.score >= 5
                          ? "orange"
                          : "red",
                      fontSize: "16px",
                    }}
                  >
                    {testResults.score ? testResults.score.toFixed(1) : 0}/10.0
                  </Text>
                </div>
              </Space>
            )
          )}
        </Space>
      </Card>
    </Space>
  );
};
