import React from 'react';
import styles from './coaching.module.css';

const VendorCell = ({ name, email, avatarUrl }) => {
  return (
    <div className={styles.vendorCell}>
      <div className={styles.avatarContainer}>
        {avatarUrl ? (
          <img src={avatarUrl} alt={name} className={styles.avatar} />
        ) : (
          <div className={styles.avatarPlaceholder}>
            {name?.charAt(0)?.toUpperCase()}
          </div>
        )}
      </div>
      <div className={styles.infoContainer}>
        <span className={styles.vendorName}>{name}</span>
        <span className={styles.vendorEmail}>{email}</span>
      </div>
    </div>
  );
};

export default VendorCell; 