import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { removeAccents } from "../../../app/utils";
import __ from "../../../app/i18n";
import styles from '../analytics.module.css';
import * as primarySlice from "../slice";
import * as secondarySlice from "../Compare/slice";
import { loadInteractionCallTypes, selectInteractionCallTypes, selectInteractionCallTypesFetched } from "../../team/interactionCallType/slice";
import { useTranslation } from "react-i18next";

export default function InteractionCallTypesFilter ({ isSecondary } = { isSecondary: false }) {
  const { updateInteractionCallTypesIdsFilters } = isSecondary ? secondarySlice : primarySlice;
  const dispatch = useDispatch();
  const interactionCallTypes = useSelector(selectInteractionCallTypes);
  const interactionCallTypesFetched = useSelector(selectInteractionCallTypesFetched);
  const [value, setValue] = useState([]);
  const [allowClear, setAllowClear] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!interactionCallTypesFetched) {
      dispatch(loadInteractionCallTypes());
    }
  }, [dispatch, interactionCallTypesFetched]);

  useEffect(() => {
    setAllowClear(!value?.label);
  }, [value])

  const options = (interactionCallTypes || []).map((item, index) => ({
    key: `interactionCallType#${index}`,
    label: item.name,
    value: item._id,
  })).sort((a, b) => a.label > b.label ? 1 : -1)

  const handleOnChange = (interactionCallTypeIds) => {
    let payload;
    if (interactionCallTypeIds && interactionCallTypeIds.length) {
      payload = interactionCallTypeIds.filter(id => !!id);
      setValue(payload);
    } else {
      setValue([]);
    }

    dispatch(updateInteractionCallTypesIdsFilters(payload));
    return true;
  };

  const selectProps = {
    mode: "multiple",
    style: { width: "250px" },
    options,
    value: value || [],
    onChange: (e) => handleOnChange(e),
    placeholder: t("meetings.filters.selectCallType"),
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = String(item.label).toUpperCase().startsWith(String(removeAccents(typed)).toUpperCase())
      return contains;
    },
    onClear: (e) => handleOnChange(e),
    className: `${styles.analyticsFilterSelect} ${styles.interactionCallTypesFilterSelect}`
  };
  
  return !!options.length && (
    <div className={styles.analyticsFilterSelectorContainer}>
      <span className={styles.analyticsFilterFilterTitle}>{t('team.callTypes.label')}</span>
      <Select {...selectProps} allowClear={allowClear}/>
    </div>
  );
}