import {
  Button,
  Checkbox,
  Form,
  Select,
  Typography,
  Badge,
} from "antd";
import _ from "lodash";
import { useState } from "react";
import AppItem from "../AppItem";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

function NotLoggedInForm(props) {
  const { t } = useTranslation();
  return (
    <AppItem app={props.app} canSave={false} canToggleStatus={false}>
      <Button>
        <a href={props.app.loginUrl}>Conectar</a>
      </Button>
    </AppItem>
  );
}

function App(props) {
  const [enabled, setEnabled] = useState(props.app.enabled);
  const [pipelines, setPipelines] = useState(props.app.selectedPipelines || []);
  const [logMeetingEnabled, setLogMeetingEnabled] = useState(
    props.app.actions.logMeeting
  );
  const [logMeetingAsNoteEnabled, setLogMeetingAsNoteEnabled] = useState(
    props.app.actions.logMeetingAsNote
  );
  const [logMeetingAsCallEnabled, setLogMeetingAsCallEnabled] = useState(
    props.app.actions.logMeetingAsCall
  );
  const [sendExtractorToCall, setSendExtractorToCall] = useState(
    props.app.actions.sendExtractorToCall
  );
  const [createActionItemsAsTasksEnabled, setCreateActionItemsAsTasksEnabled] =
    useState(props.app.actions.createActionItemsAsTasks);
  const [updateCallPropertiesEnabled, setUpdateCallPropertiesEnabled] =
    useState(props.app.actions.updateCallProperties);
  const [associateCompanyForMeeting, setAssociateCompanyForMeeting] = useState(
    props.app.actions.associateCompanyForMeeting
  );
  const [associateDealForMeeting, setAssociateDealForMeeting] = useState(
    props.app.actions.associateDealForMeeting
  );
  const [associateTicketForMeeting, setAssociateTicketForMeeting] = useState(
    props.app.actions.associateTicketForMeeting
  );
  const [associateCompanyForNote, setAssociateCompanyForNote] = useState(
    props.app.actions.associateCompanyForNote
  );
  const [associateDealForNote, setAssociateDealForNote] = useState(
    props.app.actions.associateDealForNote
  );
  const [associateTicketForNote, setAssociateTicketForNote] = useState(
    props.app.actions.associateTicketForNote
  );
  const [associateCompanyForCall, setAssociateCompanyForCall] = useState(
    props.app.actions.associateCompanyForCall
  );
  const [associateDealForCall, setAssociateDealForCall] = useState(
    props.app.actions.associateDealForCall
  );
  const [associateTicketForCall, setAssociateTicketForCall] = useState(
    props.app.actions.associateTicketForCall
  );

  if (!props.app.isLoggedIn) {
    return <NotLoggedInForm {...props} />;
  }

  const handleSave = () => {
    const newSettings = {
      ...props.app,
      enabled,
      pipelines,
      actions: {
        ...props.app.actions,
        logMeeting: logMeetingEnabled,
        logMeetingAsCall: logMeetingAsCallEnabled,
        logMeetingAsNote: logMeetingAsNoteEnabled,
        createActionItemsAsTasks: createActionItemsAsTasksEnabled,
        associateCompanyForMeeting,
        associateDealForMeeting,
        associateTicketForMeeting,
        associateCompanyForNote,
        associateDealForNote,
        associateTicketForNote,
        updateCallProperties: updateCallPropertiesEnabled,

        associateCompanyForCall,
        associateDealForCall,
        associateTicketForCall,
        sendExtractorToCall,
      },
    };

    props.handleOnSave(newSettings);
  };

  return (
    <AppItem
      app={props.app}
      onToggleStatus={(e) => setEnabled(e)}
      canSave={props.app.isLoggedIn}
      onSave={handleSave}
      canToggleStatus={props.app.isLoggedIn}
      enabled={enabled}
    >
      <Form>
        <Typography.Title level={5}>
          {t("apps.hubspot.appItem.configuration")}
        </Typography.Title>

        <Form.Item label="Pipelines" style={{ marginTop: "15px" }} required>
          <Select
            mode="multiple"
            value={pipelines}
            onChange={(v) => setPipelines(v)}
          >
            {(props.app.pipelines || []).map(({ id, label }) => (
              <Select.Option key={id}>{label.toUpperCase()}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Typography.Title level={5}>
          {t("apps.hubspot.appItem.logVideocallAs")}
        </Typography.Title>
        <div>
          <Checkbox
            checked={logMeetingEnabled}
            onChange={(e) => setLogMeetingEnabled(e.target.checked)}
          >
            {t("apps.hubspot.appItem.registerMeeting")}
          </Checkbox>
          {logMeetingEnabled ? (
            <div style={{ marginLeft: "25px" }}>
              <div>
                <Checkbox checked={true}>
                  {t("apps.hubspot.appItem.asociateContact")}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={associateCompanyForMeeting}
                  onChange={(e) =>
                    setAssociateCompanyForMeeting(e.target.checked)
                  }
                >
                  {t("apps.hubspot.appItem.asociateCompany")}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={associateDealForMeeting}
                  onChange={(e) => setAssociateDealForMeeting(e.target.checked)}
                >
                  {t("apps.hubspot.appItem.asociateDeal")}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={associateTicketForMeeting}
                  onChange={(e) => {
                    setAssociateTicketForMeeting(e.target.checked);
                  }}
                >
                  {t("apps.hubspot.appItem.asociateTicket")}
                </Checkbox>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div>
          <Checkbox
            checked={logMeetingAsNoteEnabled}
            onChange={(e) => setLogMeetingAsNoteEnabled(e.target.checked)}
          >
            {t("apps.hubspot.appItem.registerMeetingAsNote")}
          </Checkbox>
          {logMeetingAsNoteEnabled ? (
            <div style={{ marginLeft: "25px" }}>
              <div>
                <Checkbox checked={true}>
                  {t("apps.hubspot.appItem.asociateContact")}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={associateCompanyForNote}
                  onChange={(e) => setAssociateCompanyForNote(e.target.checked)}
                >
                  {t("apps.hubspot.appItem.asociateCompany")}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={associateDealForNote}
                  onChange={(e) => setAssociateDealForNote(e.target.checked)}
                >
                  {t("apps.hubspot.appItem.asociateDeal")}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={associateTicketForNote}
                  onChange={(e) => setAssociateTicketForNote(e.target.checked)}
                >
                  {t("apps.hubspot.appItem.asociateTicket")}
                </Checkbox>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {props.app.isHubSpotMigrated && (
          <div>
            <Checkbox
              checked={logMeetingAsCallEnabled}
              onChange={(e) => setLogMeetingAsCallEnabled(e.target.checked)}
            >
              Llamada <Badge count="Nuevo!" />
              <Typography.Text type="secondary">
                {" "}
                Envía propiedades desde Samu al CRM.
              </Typography.Text>
            </Checkbox>
            {logMeetingAsCallEnabled ? (
              <div style={{ marginLeft: "25px" }}>
                <div style={{ marginTop: "10px", marginBottom: 0 }}>
                  <Checkbox
                    checked={sendExtractorToCall}
                    onChange={(e) => setSendExtractorToCall(e.target.checked)}
                  >
                    {t("apps.hubspot.appItem.sendExtractorData")}
                  </Checkbox>
                </div>
                <Typography.Title
                  level={5}
                  style={{ marginTop: "10px", marginBottom: 0 }}
                >
                  {"Asociar a:"}
                </Typography.Title>
                <div>
                  <Checkbox checked={true}>
                    {t("apps.hubspot.appItem.asociateContact")}
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={associateCompanyForCall}
                    onChange={(e) =>
                      setAssociateCompanyForCall(e.target.checked)
                    }
                  >
                    {t("apps.hubspot.appItem.asociateCompany")}
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={associateDealForCall}
                    onChange={(e) => setAssociateDealForCall(e.target.checked)}
                  >
                    {t("apps.hubspot.appItem.asociateDeal")}
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={associateTicketForCall}
                    onChange={(e) =>
                      setAssociateTicketForCall(e.target.checked)
                    }
                  >
                    {t("apps.hubspot.appItem.asociateTicket")}
                  </Checkbox>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}

        <div>
          <Typography.Title level={5} style={{ marginTop: "25px" }}>
            Tareas
          </Typography.Title>
          <Checkbox
            checked={createActionItemsAsTasksEnabled}
            onChange={(e) =>
              setCreateActionItemsAsTasksEnabled(e.target.checked)
            }
          >
            Registrar compromisos detectados como tareas
          </Checkbox>
        </div>

        {props.app.actions.canUpdateCallProperties && props.app.isHubSpotCallingActive && (
            <div style={{ marginTop: "25px" }}>
              <Typography.Title level={5}>
                HubSpot Calling
              </Typography.Title>

              <Checkbox
                checked={updateCallPropertiesEnabled}
                onChange={(e) =>
                  setUpdateCallPropertiesEnabled(e.target.checked)
                }
              >
                {t("apps.hubspot.appItem.completeDetails")}
              </Checkbox>
            </div>
          )}
      </Form>
    </AppItem>
  );
}

export default App;
