import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCallTypes, selectCallTypes, selectCallTypesFetched } from "../../meetings/filters/calltypes/slice";
import { Select } from "antd";
import { removeAccents } from "../../../app/utils";
import __ from "../../../app/i18n";
import * as secondarySlice from "../Compare/slice";
import * as primarySlice from "../slice";
import styles from '../analytics.module.css';
import { useTranslation } from "react-i18next";

export default function CallTypeFilter ({ isSecondary } = { isSecondary: false }) {
  const { selectedCallType, updateAnalyticsCallTypeFilter } = isSecondary ? secondarySlice : primarySlice;
  const dispatch = useDispatch();
  const callTypes = useSelector(selectCallTypes);
  const callType = useSelector(selectedCallType);
  const callTypeFetched = useSelector(selectCallTypesFetched);
  const [value, setValue] = useState(callType);
  const { t } = useTranslation();

  useEffect(() => {
    if (!callTypeFetched) {
      dispatch(loadCallTypes());
    }

  }, [callTypeFetched]);


  const options = (callTypes || []).map((item, index) => ({
    key: `callType#${index}`,
    label: item.name,
    value: item._id,
  }));

  const handleOnChange = (callTypeId) => {
    setValue(callTypeId);
    dispatch(updateAnalyticsCallTypeFilter(callTypeId));
  };

  const selectProps = {
    options,
    value: value || t("analytics.filters.callGroups.all"),
    onChange: (e) => handleOnChange(e),
    placeholder: __('meetings.filters.selectCallType'),
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = String(item.label).toUpperCase().startsWith(String(removeAccents(typed)).toUpperCase())
      return contains;
    },
    onClear: (e) => handleOnChange(e),
    className: styles.analyticsFilterSelect
  };

  return <div className={styles.analyticsFilterSelectorContainer}>
    <span className={styles.analyticsFilterFilterTitle}>{t("analytics.filters.callGroups.label")}</span>
    <Select {...selectProps} allowClear={true}/>
  </div>
}