import { Alert } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const closedTabInformationKey = 'closedTabInformation';

const TabInformation = () => {
  const [closedMessage, setClosedMessage] = useState(!!localStorage.getItem(closedTabInformationKey));

  const { t } = useTranslation();

  if (!closedMessage) {
    return (
      <Alert
        message={t('coaching.label')}
        description={t('coaching.information')}
        type="info"
        showIcon
        closable
        style={{ marginBottom: "30px" }}
        onClose={() => {
          localStorage.setItem(closedTabInformationKey, true);
          setClosedMessage(false);
        }}
      />
    );
  }
};

export default TabInformation;