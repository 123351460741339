import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import Api from "../../app/api";
import { Alert, Form, Input, message, DatePicker, ConfigProvider } from "antd";
import { copyTextToClipboard, getAntdLocaleByPrefix } from "../../app/utils";
import { CopyOutlined } from "@ant-design/icons";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ShareMeetingModal = ({ open, onClose, meeting }) => {
  const [shareId, setShareId] = useState();
  const [shareUrl, setShareUrl] = useState();
  const [dueDateStr, setDueDateStr] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const { t, i18n } = useTranslation();

  const getShareableUrl = (shareId) => "https://web.samu.ai/share/" + shareId;
  const handleCopy = () => {
    copyTextToClipboard(getShareableUrl(shareId));

    messageApi.open({
      type: "success",
      content: "Copiado al portapapeles",
    });
  };

  const handleUpdateShareDueDate = (date) => {
    setDueDateStr(date);

    Api("updateShareableUrl", { id: shareId, dueDate: date });
  };

  useEffect(() => {
    async function fetch() {
      const { status, id, dueDate } = await Api("getShareableUrl", {
        id: meeting.id,
      });
      if (status !== "ERROR") {
        const url = getShareableUrl(id);

        copyTextToClipboard(url);
        setShareUrl(url);
        setShareId(id);
        setDueDateStr(moment(dueDate));
      }
    }

    if (open) {
      fetch();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        handleCopy();
        onClose();
      }}
      closable={false}
      cancelButtonProps={{ show: false }}
      okText={t("meetings.shareMeetingModal.copy")}
      cancelText={t("meetings.shareMeetingModal.close")}
      title={t("meetings.shareMeetingModal.title")}
    >
      {contextHolder}
      <Alert message={<>{t("meetings.shareMeetingModal.alert")}</>} />
      <Form layout="vertical">
        <Form.Item
          label={t("meetings.shareMeetingModal.fields.link")}
          style={{ marginTop: "10px", marginBottom: "0" }}
        >
          <Input
            value={shareUrl}
            addonAfter={
              <CopyOutlined
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                }}
                onClick={handleCopy}
              />
            }
          />
        </Form.Item>
        <Form.Item
          label={t("meetings.shareMeetingModal.fields.expirationDate")}
          style={{ marginTop: "10px", marginBottom: "0" }}
        >
          <ConfigProvider locale={getAntdLocaleByPrefix(i18n.language)}>
            <DatePicker
              value={dueDateStr}
              onChange={(e) => handleUpdateShareDueDate(e)}
            />
          </ConfigProvider>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ShareMeetingModal;
