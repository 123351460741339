import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import API from "../../app/api";
import { showGlobalErrors } from "../loading/slice";

export const dateFilterMap = {
  // Últimos 7 días
  last7Days: {
    from: moment().subtract(7, "days").startOf("day").toDate(),
    to: moment().endOf("day").toDate(),
  },

  lastWeek: {
    from: moment().subtract(1, "week").startOf("week").startOf("day").toDate(),
    to: moment().subtract(1, "week").endOf("week").endOf("day").toDate(),
  },

  // Últimos 15 días
  last15Days: {
    from: moment().subtract(15, "days").startOf("day").toDate(),
    to: moment().endOf("day").toDate(),
  },

  // Últimos 30 días
  last30Days: {
    from: moment().subtract(30, "days").startOf("day").toDate(),
    to: moment().endOf("day").toDate(),
  },

  // Todo el año actual
  currentYear: {
    from: moment().startOf("year").startOf("day").toDate(),
    to: moment().endOf("year").endOf("day").toDate(),
  },

  // Todo el año anterior
  lastYear: {
    from: moment().subtract(1, "year").startOf("year").toDate(),
    to: moment().subtract(1, "year").endOf("year").toDate(),
  },
};

const { from, to } = dateFilterMap.last7Days;

const initialState = {
  status: "idle",
  dateFilter: {
    from,
    to,
  },
  callTypeId: null,
  teamId: null,
  data: [],
  error: null,
};

export const getCoachingData = createAsyncThunk(
  "coaching/getCoachingData",
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState().coaching;
      const payload = {
        dateFilter: {
          dateStart: new Date(state.dateFilter.from),
          dateEnd: new Date(state.dateFilter.to),
        },
      };

      // Agregar teamId solo si existe
      if (state.teamId) {
        payload.teamId = state.teamId;
      }

      // Agregar callTypeId solo si existe
      if (state.callTypeId) {
        payload.callTypeId = state.callTypeId;
      }

      const response = await API("analyticsCustomScore", payload);
      if (response && response.errors) {
        dispatch(showGlobalErrors(response.errors));
        return rejectWithValue(response.errors);
      }

      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const coachingSlice = createSlice({
  name: "coaching",
  initialState,
  reducers: {
    updateDateFilter: (state, action) => {
      state.dateFilter = action.payload;
    },
    updateCallTypeFilter: (state, action) => {
      state.callTypeId = action.payload;
    },
    updateTeamFilter: (state, action) => {
      state.teamId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoachingData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCoachingData.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
      })
      .addCase(getCoachingData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Selectors
export const selectCoachingData = (state) => state.coaching?.data;
export const selectIsLoading = (state) => state.coaching?.status === "loading";
export const selectError = (state) => state.coaching?.error;
export const selectTeamId = (state) => state.coaching?.teamId;
export const selectDateFilter = (state) => state.coaching?.dateFilter;
export const selectCallTypeId = (state) => state.coaching?.callTypeId;

// Action creators para actualizar filtros y obtener datos
export const updateCoachingFilters = (filters) => (dispatch) => {
  dispatch(updateDateFilter(filters));
};

export const updateCoachingCallTypeFilter = (callTypeId) => (dispatch) => {
  dispatch(updateCallTypeFilter(callTypeId));
};

export const updateCoachingTeamFilter = (teamId) => (dispatch) => {
  dispatch(updateTeamFilter(teamId));
};

export const { updateDateFilter, updateCallTypeFilter, updateTeamFilter } =
  coachingSlice.actions;

export default coachingSlice.reducer;
