import { selectMessages } from "./slice";
import { useSelector } from "react-redux";
import styles from "./styles.module.css";
import { Comment } from "antd";
import moment from "moment";
import Message from "./Message";
import Audio from "./Audio";
import Image from "./Image";
import Pdf from "./Pdf";
import File from "./File";
export default function Chat() {
  const messages = useSelector(selectMessages);

  const getComponent = (comment) => {
    switch (comment.outputType) {
      case "audio":
        return <Audio comment={comment} />;
      case "text":
        return <Message comment={comment} />;
      case "image":
        return <Image comment={comment} />;
      case "document":
          if(comment.filename.endsWith(".pdf")){
            return <Pdf comment={comment} />;
          } else {
            return <File comment={comment} />;
          }
      default:
        return null;
    }
  };

  return <div>{messages.map(getComponent)}</div>;
}
