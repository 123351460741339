import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadTeams, selectTeams } from "../../team/teams/slice";
import { updateTeamFilter } from "../slice";
import styles from '../coaching.module.css';

export default function TeamFilter() {
  const dispatch = useDispatch();
  const teams = useSelector(selectTeams);

  useEffect(() => {
    dispatch(loadTeams());
  }, []);

  const handleChange = (teamId) => {
    dispatch(updateTeamFilter(teamId));
  };

  const options = teams.map(team => ({
    label: team.name,
    value: team._id
  }));

  return (
    <div className={styles.filterContainer}>
      <span className={styles.filterTitle}>Equipo</span>
      <Select
        allowClear
        options={options}
        onChange={handleChange}
        className={styles.filterSelect}
        placeholder="Seleccione equipo"
      />
    </div>
  );
} 