import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  MessageOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DoReDo from "./DoReDo";
import styles from "./MediaControls.module.css";
import { showCC, toggleCC } from "./slice";
import VelocityControl from "./VelocityControl";
import SpeakerNavigation from "./SpeakerNavigation";

export default function MediaControls({ meeting, videoRef }) {
  const [isPlaying, setPlaying] = useState(false);
  const dispatch = useDispatch();

  const handleFullScreen = () => {
    videoRef.current.requestFullscreen();
  };

  const handleVideoPlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }

    setPlaying(!isPlaying);
  };

  useEffect(() => {
    videoRef.current.onplay = () => setPlaying(true);
    videoRef.current.onpause = () => setPlaying(false);
  }, [videoRef]);

  const handleCC = () => {
    dispatch(toggleCC());
  };

  return (
    <div className={styles.mediaControlsContainer}>
      <DoReDo videoRef={videoRef} type={'back'}/>
      {isPlaying ? (
        <PauseCircleOutlined
          style={{ fontSize: "40px", margin: "0 15px" }}
          onClick={handleVideoPlay}
        />
      ) : (
        <PlayCircleOutlined
          style={{ fontSize: "40px", margin: "0 15px" }}
          onClick={handleVideoPlay}
        />
      )}

      <DoReDo videoRef={videoRef} type={'forward'}/>

      <SpeakerNavigation meeting={meeting} videoRef={videoRef} />

      <MessageOutlined
        style={{ fontSize: "40px", margin: "0 15px" }}
        onClick={handleCC}
      />
      <VelocityControl videoRef={videoRef}/>
      <FullscreenOutlined
        style={{ fontSize: "40px", margin: "0 15px" }}
        onClick={handleFullScreen}
      />
    </div>
  );
}
