import { Badge, Button, Card, Tooltip } from "antd";
import Meta from "antd/lib/card/Meta";
import styles from "./styles.module.css";
import MeetingTags from "../tags/MeetingTags";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AppItem({ app }) {
  const { t } = useTranslation();
  let appTags = [];
  if (app && app.displayName === 'Custom Score') {
    appTags = [{ name: t("apps.tags.beta") }]
  }

  return (
    <Link to={`/apps/${app._id}`}>
      <Card className={styles.libraryItem} style={{ borderTop: "none", minHeight: "195px" }}>
        <Meta
          avatar={<img style={{ width: "50px" }} src={app.icon} />}
          title={
            <>
              <div>{t(app.displayName)}</div>

              <div>
                {app.inUse ? (
                  <>
                    <Tooltip title={t("apps.tags.activeApp")}>
                      <>
                        <Badge
                          status="success"
                          style={{
                            width: "25px",
                            position: "absolute",
                            right: 0,
                            top: "10px",
                            fontWeight: "10px",
                          }}
                        />
                      </>
                    </Tooltip>
                    <MeetingTags meeting={{ tags: [...appTags, { name: t("apps.tags.installed") }] }} />
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          }
          description={
            <div style={{ marginTop: "15px" }}>{t(app.description)}</div>
          }
        />
      </Card>
    </Link>
  );
}
