import React, { useState } from "react";
import { Card, Modal, Tooltip } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";
import __ from "../../app/i18n";

const PDFPreview = ({ fileUrl, filename }) => {
  const [visible, setVisible] = useState(false);

  const openPDF = () => setVisible(true);
  const closePDF = () => setVisible(false);

  return (
    <div>
      <Tooltip title={__("conversation.chat.pdf")}>
        <Card
          hoverable
          className={styles.pdfCard}
          bodyStyle={{ display: "flex", alignItems: "center", padding: "10px" }}
          onClick={openPDF}
        >
          <div className={styles.fileName}>{__("conversation.chat.pdf")}</div>
          <FilePdfOutlined className={styles.pdfIcon} />
        </Card>
      </Tooltip>

      <Modal
        open={visible}
        footer={null}
        onCancel={closePDF}
        width="80vw"
        style={{ top: 20 }}
        bodyStyle={{ padding: 0, height: "80vh" }}
        title={null}
        closable={false} 
      >
        <iframe
          src={fileUrl}
          width="100%"
          height="100%"
          style={{ border: "none" }}
          title={filename}
        />
      </Modal>
    </div>
  );
};

export default PDFPreview;
