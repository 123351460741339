import styles from "./styles.module.css";
import { Comment } from "antd";
import moment from "moment";
import { getTimeInTimezone } from "./Message";
import PDFPreview from "./PdfPreview";

export default function File({ comment }) {

  return (
    <Comment
      className={
        styles.chatComment +
        " conversation-message-" +
        String(comment.type).toLowerCase()
      }
      key={`comment:${comment.id}`}
      dataId={`comment:${comment.id}`}
      author={<a>{comment.name}</a>}
      content={
        <p className={styles.messageChatContent}>
          <PDFPreview fileUrl={comment.path} filename={comment.filename} />
          <span className={styles.messageChatContentTime}>
            {comment.timezone
              ? getTimeInTimezone(comment.date, comment.timezone)
              : moment(comment.date).format("HH:mm")}
          </span>
        </p>
      }
    />
  );
}
