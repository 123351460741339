import { useState } from "react";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Input, Tooltip, Typography } from "antd";
import styles from "./styles.module.css";
import React from "react";
import __ from "../../../app/i18n";

export const EditableText = ({
  minWidthInEditMode = null,
  value,
  onSave,
  ...extraProps
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [editMode, setEditMode] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveInput = () => {
    onSave(inputValue);
    setEditMode((beforeValue) => !beforeValue);
  };

  const handleNoFocusInput = (e) => {
    setTimeout(() => {
      setEditMode(false);
    }, 100);
  };

  return (
    <div>
      {editMode ? (
        <div
          className={styles.editModeContainer}
          style={minWidthInEditMode && { minWidth: minWidthInEditMode }}
        >
          <Input
            autoFocus={true}
            onBlur={handleNoFocusInput}
            value={inputValue}
            onChange={handleInputChange}
          />
          <Button
            onClick={() => handleSaveInput()}
            onMouseDown={() => handleSaveInput()}
            style={{ marginLeft: "10px" }}
            size="small"
            type="text"
            icon={<SaveOutlined />}
          />
        </div>
      ) : (
        <div className={styles.textModeContainer}>
          <Typography.Title {...extraProps}>{value}</Typography.Title>
          <Tooltip title={__("generic.buttons.edit")}>
            <Button
              style={{ marginLeft: "10px" }}
              onClick={() => setEditMode((beforeValue) => !beforeValue)}
              size="small"
              type="text"
              icon={<EditOutlined />}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
