import { Tabs, Typography } from "antd";
import AnalyticLines from "./AnalyticLines";
import WeeklyLines from "./WeeklyLines";
import { toTimeFormat } from "./utils/date";
import { useTranslation } from "react-i18next";

export default function PeriodGraphs({ userAnalytics, title, formatter, className, useTimeFormatter }) {
  const { t } = useTranslation();

  if (!userAnalytics) {
    return
  }
  
  const renderDailyGraph = () => {
    const config = {
      style: { marginTop: "20px" },
      userAnalytics: userAnalytics.daily,
      formatter
    };

    if (formatter) {
      config.formatter = formatter;
    }

    if (useTimeFormatter) {
      config.formatter = toTimeFormat;
    }

    return (
      <div>
        <AnalyticLines {...config} />
      </div>
    );
  };

  const renderWeeklyGraph = () => {
    const config = {
      style: { marginTop: "20px" },
      userAnalytics: userAnalytics.weekly,
    };

    if (formatter) {
      config.formatter = formatter;
    }

    if (useTimeFormatter) {
      config.formatter = toTimeFormat;
    }

    return <WeeklyLines {...config} />;
  };

  const renderMonthsGraph = () => {
    const config = {
      style: { marginTop: "20px" },
      userAnalytics: userAnalytics.monthly,
    };

    if (formatter) {
      config.formatter = formatter;
    }

    if (useTimeFormatter) {
      config.formatter = toTimeFormat;
    }

    return <AnalyticLines {...config} />;
  };

  const items = [
    {
      label: t("analytics.evolution.monthly"),
      key: "beh#month#tab",
      children: renderMonthsGraph(),
    },
    {
      label: t("analytics.evolution.weekly"),
      key: "beh#week#tab",
      children: renderWeeklyGraph(),
    },
  ];
  const hasDailyData = userAnalytics.daily && Object.keys(userAnalytics.daily).length;
  if (hasDailyData) {
    items.push({
      label: t("analytics.evolution.daily"),
      key: "beh#day#tab",
      children: renderDailyGraph(),
    })
  }

  return (
    <div style={{ marginTop: "30px" }}>
      <Typography.Title
        className={className}
        level={3}
        style={{ marginBottom: "15px", display: "inline-block" }}
      >
        {title}
      </Typography.Title>
      <Tabs
        defaultActiveKey="2"
        items={items}
      />
    </div>
  );
}
