import { Button, Col, Row, Statistic, Typography } from "antd";
import { useTranslation } from "react-i18next";

function StatItem ({ title, value, ...other }) {
  return <Statistic title={title} value={value} {...other}/>
}

export default function Status(props) {
  const activeUsers = (props.users || []).filter(u => u.enabled).length;
  const allUsers = (props.users || []).length;
  const activeCalendars = (props.users || []).filter(u => props.isGoogleConnected(u) || props.isTeamsConnected(u)).length;
  const activeWhatsApp = (props.users || []).filter(u => props.isWhatsAppConnected(u)).length;
  const { t } =useTranslation();

  return (
      <Row className="user-stats" gutter={24} style={{ padding: "10px", margin: 0, marginBottom: "15px", borderRadius: "5px" }}>
        <Col span={6}>
          <StatItem title={t("team.users.status.actives")} value={activeUsers} loading={props.isFetching} suffix={<span style={{ color: "#bfbfbf" }}>{ '/' + allUsers }</span>} />
        </Col>
        { props.seats && <Col span={6}>
          <StatItem title={t("team.users.status.licenses")} value={props.seats} loading={props.isFetching}/>
        </Col>  }
        <Col span={6}>
          <StatItem title={t("team.users.status.calendars")} value={activeCalendars} loading={props.isFetching}/>
        </Col>
        <Col span={6}>
          <StatItem title={t("team.users.status.whatsapps")} value={activeWhatsApp} loading={props.isFetching}/>
        </Col>
      </Row>
  );
}
