import AnalyticBar from "./AnalyticBar";
import { Typography } from "antd";
import AnalyticsTabContent from "./AnalyticsTabContent";
import PeriodGraphs from './PeriodGraphs';
import { useTranslation } from "react-i18next";

export default function QuestionRateTab({ analyticsState }) {
  const { t } = useTranslation();

  return (
    <AnalyticsTabContent>
      <Typography.Title className={"analyticsTabTitle"} level={3} style={{ marginBottom: '15px' }}>{t("analytics.dashboard.tabs.activity.children.questionRate.title")}</Typography.Title>
      <AnalyticBar userAnalytics={analyticsState.questionRate.questionRates} prop="avg" avg={analyticsState.questionRate.avg} itemKeyName={'Promedio del usuario'}/>
      <PeriodGraphs title={t("analytics.dashboard.tabs.activity.children.questionRate.evolution")} userAnalytics={analyticsState.questionRate.periods}/>
    </AnalyticsTabContent>
  );
}
