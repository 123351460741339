import { Button, Select } from "antd";
import { useSelector } from "react-redux";
import { Download } from "tabler-icons-react";
import __ from "../../app/i18n";
import Api from "../../app/api";
import { useEffect, useState } from "react";
import moment from "moment";
import { firstUp } from "../meetings/Item";
import { selectCoachingAppCreatedAt } from "../session/slice";

const DownloadMonthlyReport = () => {
  const [months, setMonths] = useState([]);
  const createdAt = useSelector(selectCoachingAppCreatedAt);
  const [currentMonth, setCurrentMonth] = useState(
    moment().subtract(1, "month").startOf("month").format("YYYY-MM")
  );

  useEffect(() => {
    const render = () => {
      const map = new Map();
      let from = moment(createdAt).startOf("month");
      while (from.isBefore(moment().startOf("month"))) {
        const label =
          firstUp(from.format("MMMM").replace("º", "")) +
          " " +
          from.format("YYYY");
        const index = from.format("YYYY-MM");

        map.set(index, { key: index, value: index, label });
        from.add(1, "month");
      }

      setMonths(Array.from(map.values()).reverse());
    };

    if (createdAt) {
      return render();
    }
  }, [createdAt]);

  const handleMonthChange = (value) => {
    const selectedDate = moment(value).startOf("month").format("YYYY-MM"); // Formatear a la estructura deseada
    setCurrentMonth(selectedDate);
  };

  return (
    <>
      <Select
        style={{ width: "150px" }}
        value={currentMonth}
        onChange={handleMonthChange}
      >
        {months.map((month) => (
          <Select.Option key={month.key} value={month.value}>
            {month.label}
          </Select.Option>
        ))}
      </Select>
      <Button
        icon={<Download size={12} />}
        style={{ fontSize: "12px" }}
        onClick={async () => {
          const response = await Api("getCoachingReport", {
            date: currentMonth,
          });
          if (response && response.reportUrl) {
            document.location.href = response.reportUrl;
          }
        }}
      >
        <span style={{ marginLeft: "5px" }}>
          {__("reports.downloadMonthlyReport")}
        </span>
      </Button>
    </>
  );
};

export default DownloadMonthlyReport;
