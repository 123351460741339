import styles from "./styles.module.css";
import { Comment } from "antd";
import moment from "moment";
import { getTimeInTimezone } from "./Message";
import { Image } from "antd";

export default function ImagePreview({ comment }) {
  return (
    <Comment
      className={
        styles.chatComment +
        " conversation-message-" +
        String(comment.type).toLowerCase()
      }
      key={`comment:${comment.id}`}
      dataId={`comment:${comment.id}`}
      author={<a>{comment.name}</a>}
      content={
        <p className={styles.messageChatContent}>
          <Image src={comment.path} width={300} />
          <span
            style={{ margin: "5px 0" }}
            className={styles.messageChatContentTime}
          >
            {comment.timezone
              ? getTimeInTimezone(comment.date, comment.timezone)
              : moment(comment.date).format("HH:mm")}
          </span>
        </p>
      }
    />
  );
}
