import {
  Avatar,
  Button,
  Empty,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useState } from "react";
import styles from "./styles.module.css";
import GoogleCalendarLogo from "./../../team/general/profile/tabs/assets/meetLogo.png";
import HubspotLogo from "./../../team/general/profile/tabs/assets/hubspotLogo.svg";
import TeamsLogo from "./../../team/general/profile/tabs/assets/teamsLogo.png";
import moment from "moment";
import { firstUp } from "../../meetings/Item";
import { useDispatch, useSelector } from "react-redux";
import { updateMeetingSyncStatus } from "../slice";
import {
  CalendarTime,
  CircleX,
  HandStop,
  Tool,
  CircleCaretRight,
  Video,
} from "tabler-icons-react";
import Loading from "../../loading/Index";
import __ from "../../../app/i18n";
import PrivacyModal from "../../privacy/Modal";
import { EditOutlined } from "@ant-design/icons";
import {
  updateLangMeeting,
  selectIsFetchingLangUpdate,
} from "../../library/slice";
import { appMeetingLangs } from "../../../app/utils";
import i18n from "../../../app/i18n/config";
import { useTranslation } from "react-i18next";

function renderStatus(meeting) {
  if (["COMPLETED", "INDEXED"].includes(meeting.status)) {
    return (
      <>
        <CircleCaretRight
          style={{
            display: "inline-block",
            marginRight: "5px",
            verticalAlign: "top",
          }}
        />
        <a href={`/meeting/${meeting._id}`}>
          <Typography.Text type="secondary">
            {__("team.meetings.see")}
          </Typography.Text>
        </a>
      </>
    );
  }

  if (["RECORDED"].includes(meeting.status)) {
    return (
      <>
        <Tool
          style={{
            display: "inline-block",
            marginRight: "5px",
            verticalAlign: "top",
          }}
        />
        <Typography.Text type="secondary">
          {__("team.meetings.working")}
        </Typography.Text>
      </>
    );
  }

  if (["RECORDING"].includes(meeting.status)) {
    return (
      <>
        <Video
          style={{
            display: "inline-block",
            marginRight: "5px",
            verticalAlign: "top",
          }}
        />
        <Typography.Text type="secondary">
          {__("team.meetings.recording")}
        </Typography.Text>
      </>
    );
  }

  if (meeting.status === "STOPPING" || meeting.status === "UNACCEPTED") {
    return (
      <>
        <HandStop
          style={{
            display: "inline-block",
            marginRight: "5px",
            verticalAlign: "top",
          }}
          color="red"
        />
        <Typography.Text type="secondary">
          {__("team.meetings.notAccepted")}
        </Typography.Text>
      </>
    );
  }

  if (meeting.status === "IGNORED") {
    <>
      <CircleX
        style={{
          display: "inline-block",
          marginRight: "5px",
          verticalAlign: "top",
        }}
        color="red"
      />
      <Typography.Text type="secondary">
        {meeting.reason
          ? __(
              "team.meetings." +
                String(meeting.reason)
                  .toLowerCase()
                  .replace(/ /gi, "_")
                  .replace(".", "")
            )
          : ""}
      </Typography.Text>
    </>;
  }

  return (
    <>
      <CalendarTime
        style={{
          display: "inline-block",
          marginRight: "5px",
          verticalAlign: "top",
        }}
        color="green"
      />
      <Typography.Text type="secondary">Agendada</Typography.Text>
    </>
  );
}

const getLogo = (provider) => {
  let logo;

  switch(provider) {
    case 'GOOGLE':
      logo = GoogleCalendarLogo;
      break;
    case 'MICROSOFT':
      logo = TeamsLogo;
      break;
    case 'HUBSPOT':
      logo = HubspotLogo;
      break;
    default:
      logo = GoogleCalendarLogo;
      break;
  }

  return logo;
}

function truncateString(str, maxLength) {
  if (str.length > maxLength) {
      return str.substring(0, maxLength - 3) + '...';
  }
  return str;
}

function getColumns(
  handleSamuAction,
  canEdit,
  user,
  meetingPrivacyOpen,
  setMeetingPrivacyOpen,
  handleMeetingLangUpdate,
  isUpdatingLang
) {
  const columns = [
    {
      title: i18n.t("myNextMeetings.table.columns.meeting"),
      dataIndex: "name",
      sorter: {
        compare: (a, b) => (a.name < b.name ? 1 : -1),
      },
      render: (name, o) => (
        <>
          <Avatar
            className={styles.headerMeetingImg}
            src={getLogo(o.provider)}
          />
          <span className={styles.headerMeetingNameContent}>
            <Typography.Title className={styles.headerMeetingName} level={5}>
              <span style={{ display: "flex" }}>{(name && truncateString(name, 75)) || "Reunión"}</span>
              {(o.invited) ? (
              // {(o.host.id !== user._id && user.role === 1) ? (
                <span>
                  <Tag
                    style={{
                      marginTop: "5px",
                      marginBottom: "3px",
                      display: "inline-block",
                      verticalAlign: "middle",
                      border: "1px solid #1a91ff",
                      color: "#1a91ff",
                      lineHeight: "1em",
                      padding: "3px 5px"
                    }}
                  >
                    Invitado por {[o.host.name, o.host.lastName].join(" ")}
                  </Tag>
                </span>
              ) : (
                ""
              )}
            </Typography.Title>
            <Typography.Text style={{ color: "#9e9e9e" }} level={5}>
              {[o.host.name, o.host.lastName].join(" ")}{" "}
              {o.attendeesQty > 1
                ? ` + ${o.attendeesQty - 1} participantes`
                : ""}
            </Typography.Text>
          </span>
        </>
      ),
      width: "50%",
    },
    {
      title: i18n.t("myNextMeetings.table.columns.date"),
      dataIndex: "date",
      sorter: {
        compare: (a, b) => (moment(a.from).isBefore(moment(b.from)) ? 1 : -1),
      },
      render: (_, o) => {
        const dayName = firstUp(moment(o.from).format("dddd").replace("º", ""));
        const dayDate = moment(o.from).format("D/M/YYYY");
        const from = moment(o.from).format("HH:mm");
        const to = moment(o.to).format("HH:mm");

        return (
          <>
            <div>
              {dayName} {dayDate}
            </div>
            <div style={{ color: "#9e9e9e" }}>
              {from} {o.to ? ` - ${to}` : ""}
            </div>
          </>
        );
      },
      width: "20%",
    },
    {
      width: "15%",
      title: i18n.t("myNextMeetings.table.columns.addToSamu"),
      dataIndex: "samu",
      render: (_, o) => {
        if (!o.canSchedule && o.reason) {
          return (
            <span>
              <CircleX
                style={{
                  display: "inline-block",
                  marginRight: "5px",
                  verticalAlign: "top",
                }}
                color="red"
              />
              <Typography.Text type="secondary">
                {__(
                  "team.meetings." +
                    String(o.reason)
                      .toLowerCase()
                      .replace(/ /gi, "_")
                      .replace(".", "")
                )}
              </Typography.Text>
            </span>
          );
        }

        return canEdit &&
          (!user || o.host.id === user._id) &&
          moment(o.from || o.dateFrom).isAfter(moment()) ? (
          <Switch
            onChange={(checked) =>
              canEdit && o.canSchedule
                ? handleSamuAction(o._id, checked)
                : () => {}
            }
            checked={o.status === "IGNORED" ? false : true}
            style={{ cursor: "pointer !important" }}
          />
        ) : (
          <>
            {o.status === "IGNORED" ? (
              <>
                <CircleX
                  style={{
                    display: "inline-block",
                    marginRight: "5px",
                    verticalAlign: "top",
                  }}
                  color="red"
                />
                <Typography.Text type="secondary">
                  {o.reason
                    ? __(
                        "team.meetings." +
                          String(o.reason)
                            .toLowerCase()
                            .replace(/ /gi, "_")
                            .replace(".", "")
                      )
                    : ""}
                </Typography.Text>
              </>
            ) : (
              renderStatus(o)
            )}
          </>
        );
      },
    },
    {
      width: "5%",
      title: i18n.t("myNextMeetings.table.columns.lang"),
      dataIndex: "lang",
      render: (_, o) => {
        if (canEdit && (!user || o.host.id === user._id)) {
          const langOptions = appMeetingLangs.map(({ flag, value }) => ({
            value,
            label: <span className={`flag flag-country-${i18n.t(flag)}`}></span>,
            children: <span className={`flag flag-country-${flag}`}></span>
          }));

          return (
            <Select
              loading={isUpdatingLang}
              value={o.lang}
              onChange={(lang) => handleMeetingLangUpdate({ lang, id: o._id })}
              style={{ minWidth: "10px", display: "inline-block" }}
              options={langOptions}
              optionLabelProp="label"
            />
          );
        } else {
          const langFlag = appMeetingLangs.find(l => l.value === o.lang)?.flag;
          return <span className={`flag flag-country-${i18n.t(langFlag)}`}></span>;
        }
      },
    },
  ];

  columns.push({
    width: "28%",
    title: i18n.t("myNextMeetings.table.columns.privacy.label"),
    dataIndex: "privacy",
    render: (_, o) => {
      const meeting = { ...o, id: o._id };

      return (
        <>
          {o.visibility && o.visibility.type && o.visibility.type !== "ALL" ? (
            <>
              <Tooltip title={__("team.meetings.visibility.restricted")}>
                {user.role === 2 ? (
                  <Button
                    style={{ marginRight: "5px", cursor: "pointer" }}
                    icon={<EditOutlined />}
                    onClick={() => setMeetingPrivacyOpen(o)}
                  ></Button>
                ) : (
                  ""
                )}
                Restringida
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title={__("team.meetings.visibility.public")}>
                {user.role === 2 ? (
                  <Button
                    style={{ marginRight: "5px", cursor: "pointer" }}
                    icon={<EditOutlined />}
                    onClick={() => setMeetingPrivacyOpen(o)}
                  ></Button>
                ) : (
                  ""
                )}
                {i18n.t("meetings.visibility.public")}
              </Tooltip>
            </>
          )}

          <PrivacyModal
            meeting={meeting}
            open={meetingPrivacyOpen && meetingPrivacyOpen._id === meeting._id}
            onClose={() => setMeetingPrivacyOpen()}
          />
        </>
      );
    },
  });

  return columns;
}

function groupMeetingsByDay(meetings) {
  const key = (meeting) => {
    const date = new Date(meeting.from);

    return moment(date).format("YYYY-MM-DD");
  };

  const map = new Map();

  meetings.forEach((meeting) => {
    const k = key(meeting);
    let periodMeetings = map.get(k);

    if (periodMeetings) {
      periodMeetings.push(meeting);
    } else {
      periodMeetings = [meeting];
    }

    map.set(key(meeting), periodMeetings);
  });

  return map;
}

const MyNextMeetingsList = ({
  meetings,
  isMeetingsFetching,
  user,
  canEdit,
  onEdit,
  title,
  date,
  host,
}) => {
  const dispatch = useDispatch();
  const isUpdatingLang = useSelector(selectIsFetchingLangUpdate);
  const { i18n } = useTranslation();
    moment.locale(i18n.language);

  const map = groupMeetingsByDay(
    [...meetings]
      .map(m => {
        let invited = false;
        const meeting = { ...m };
        if (user.role === 1 && (m.host && m.host.id && m.host.id !== user._id)) {
          invited = true;
        } else if (user.role === 2) {
          
          if (host && host.id && Array.isArray(m.users) && m.users.length && m.users.includes(host.id) && m.host && m.host.id !== (host.id || user._id )) {
            invited = true;
          }
        }

        meeting.invited = invited;

        return meeting;
      }).filter((m) => {
        let valid;
        if (date) {
          valid = moment(m.from).isBetween(
            moment(date),
            moment(date).add(1, "day").subtract(1, "min")
          );
        } else {
          valid = moment(m.from).isAfter(moment());
        }

        if (m.status === 'IGNORED_DUPLICATED') {
          valid = false;
        }

        return valid && ((user.role === 2 && !host) || (host && host.id === m.host.id) || (m.invited));
      })
      .sort((a, b) => (moment(a.from).isBefore(moment(b.from)) ? -1 : 1))
  );
  
  const [meetingPrivacyOpen, setMeetingPrivacyOpen] = useState();

  function defaultOnEdit(meetingId, checked) {
    dispatch(
      updateMeetingSyncStatus({
        id: meetingId,
        status: checked ? "SCHEDULED" : "IGNORED",
      })
    );
  }

  const handleSamu = (meetingId, checked) => {
    if (canEdit) {
      if (onEdit) {
        onEdit(meetingId, checked);
      } else {
        defaultOnEdit(meetingId, checked);
      }
    }
  };

  const handleMeetingLangUpdate = ({ lang, id }) => {
    dispatch(updateLangMeeting({ lang, id }));
  };

  if (isMeetingsFetching) {
    return <Loading />;
  }

  const renderSections = () => {
    return (
      <>
        {[...map.keys()].map((key) => {
          const dayName = firstUp(moment(key).format("dddd").replace("º", ""));
          const dayDate = moment(key).format("D/M");

          return (
            <div>
              <Typography.Title
                style={{ color: "#747474", fontWeight: 300 }}
                level={4}
                className={styles.title}
              >
                {dayName} {dayDate}
              </Typography.Title>
              <Table
                columns={getColumns(
                  handleSamu,
                  canEdit,
                  user,
                  meetingPrivacyOpen,
                  setMeetingPrivacyOpen,
                  handleMeetingLangUpdate,
                  isUpdatingLang
                )}
                rowKey={(record) => record.id}
                dataSource={map.get(key)}
                loading={isMeetingsFetching}
                pagination={false}
                style={{ marginBottom: "30px" }}
              />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div
      style={{ position: "relative", paddingLeft: "25px" }}
      className="scheduled-table"
    >
      {map.size ? renderSections() : <Empty />}
    </div>
  );
};

export default MyNextMeetingsList;
