import { Empty, Typography } from "antd";
import Section from "../../meeting/sections/Section";
import __ from "../../../app/i18n";
import moment from "moment";
import { useTranslation } from "react-i18next";

const FrameworkSection = ({ title, description, examples }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography.Title style={{ color: '#b389ff' }} level={5}>{title}</Typography.Title>
      <p style={{ color: 'grey', fontSize: "14px" }}>{description}</p>

      {examples && (
        <>
          <b>{t("meetings.details.sections.apps.toStartToKeepToStop.examples")}</b>
          <p style={{ color: 'grey', marginTop: "5px", fontSize: "14px" }}>{examples}</p>
        </>
      )}
    </>
  );
};

export default function ToStartKeepStopTab({ data }) {
  const { start, stop, keep } = data.properties || {};

  if (start && stop && keep) {
    return (
      <Section key={data.id} sectionKey={data.id} title={data.name}>

        <FrameworkSection title={'Start'} description={start.description} examples={start.examples}/>
        <FrameworkSection title={'Keep'} description={keep.description} examples={keep.examples}/>
        <FrameworkSection title={'Stop'} description={stop.description} examples={stop.examples}/>
      </Section>
    );
  } else {
    return <Empty />;
  }
}
