import AnalyticBar from "./AnalyticBar";
import { Typography } from "antd";
import AnalyticsTabContent from "./AnalyticsTabContent";
import PeriodGraphs from "./PeriodGraphs";
import { useTranslation } from "react-i18next";

export default function TotalCallVolumeTab({ analyticsState }) {
  const { t } = useTranslation();
  const mode = "fullscreen";

  return (
    <AnalyticsTabContent>
      <div style={mode === "half" ? { display: "flex" } : {}}>
        <div className={mode === "half" ? "tabContent2Items" : ""}>
          <Typography.Title
            className={"analyticsTabTitle"}
            level={3}
            style={{ marginBottom: "15px" }}
          >
            {t("analytics.dashboard.tabs.activity.children.callVolume.title")}
          </Typography.Title>
          <AnalyticBar
            className={"dtabContent2Items"}
            userAnalytics={analyticsState.totalCallVolume.volumes}
            avg={analyticsState.totalCallVolume.avg}
            itemKeyName={"Total del usuario"}
          />
        </div>
        <PeriodGraphs title={t("analytics.dashboard.tabs.activity.children.callVolume.evolution")} userAnalytics={analyticsState.totalCallVolume.periods}/>
      </div>
    </AnalyticsTabContent>
  );
}
