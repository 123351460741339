import { Select } from "antd";

import { useDispatch, useSelector } from "react-redux";
import __ from "../../../../app/i18n";
import { selectedSeen, updateSeenSelection } from "../slice";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const getValueKey = (selectorSeen) => {
  if (selectorSeen === true) {
    return 'seen-filter-yes';
  } else if (selectorSeen === false) {
    return 'seen-filter-no';
  } else {
    return 'undefined';
  }
}

export default function SeenFilter() {
  const dispatch = useDispatch();
  const selectorSeen = useSelector(selectedSeen)
  const [value, setValue] = useState(getValueKey(selectorSeen))
  const { t } = useTranslation();
  
  const onSelectSeenOption = (v) => {
    let seenValue = null;

    if (v === 'seen-filter-yes') {
      seenValue = true;
    } else if (v === 'seen-filter-no') {
      seenValue = false;
    }

    setValue(v);
    dispatch(updateSeenSelection(seenValue));
  };

  return (
    <div style={{ margin: "15px 15px" }}>
      <Select style={{ width: "100%" }} onChange={onSelectSeenOption} value={value}>
        <Select.Option key={"undefined"}>-</Select.Option>,
        <Select.Option key={"seen-filter-yes"}>{t("generic.labels.yes")}</Select.Option>,
        <Select.Option key={"seen-filter-no"}>{t("generic.labels.no")}</Select.Option>,
      </Select>
    </div>
  );
}
