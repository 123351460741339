import { Button, Select } from "antd";
import { useSelector } from "react-redux";
import * as primarySlice from "./slice";
import * as secondarySlice from "./Compare/slice";
import { Download } from "tabler-icons-react";
import __ from "../../app/i18n";
import Api from "../../app/api";
import { useEffect, useState } from "react";
import moment from "moment";
import { firstUp } from "../meetings/Item";
import { useTranslation } from "react-i18next";
import 'moment/locale/pt';

const DownloadMonthlyReportButton = (
  { isSecondary } = { isSecondary: false }
) => {
  const { i18n } = useTranslation();
  const { selectAnalyticsState } = isSecondary ? secondarySlice : primarySlice;
  const accountCreatedAt = useSelector(primarySlice.selectAccountCreatedAt);
  const analyticsState = useSelector(selectAnalyticsState);
  const [months, setMonths] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment().format('YYYY-MM'));

  useEffect(() => {
    const render = () => {
      const map = new Map();

      
      moment.locale(i18n.language);

      let from = moment(accountCreatedAt).startOf('month');
      while (from.isSameOrBefore(moment().startOf('month'))) {
        const label = firstUp(from.format("MMMM").replace("º", "")) + ' ' + from.format('YYYY');
        const index = from.format('YYYY-MM');

        map.set(index, { key: index, value: index, label });
        from.add(1, 'month');
      }

      setMonths(Array.from(map.values()).reverse());
    };

    if (accountCreatedAt && analyticsState) {
      return render();
    }

  }, [accountCreatedAt, analyticsState]);

  if (!analyticsState) {
    return <h2>Loading</h2>;
  }

  return (
    <>
      <Select
        style={{ width: "150px" }}
        value={currentMonth}
        options={months}
        onChange={(v) => setCurrentMonth(v)}
      ></Select>
      <Button
        icon={<Download size={12} />}
        style={{ fontSize: "12px" }}
        onClick={async () => {
          const response = await Api("getMonthlyReport", {
            date: currentMonth,
          });
          if (response && response.reportUrl) {
            document.location.href = response.reportUrl;
          }
        }}
      >
        <span style={{ marginLeft: "5px" }}>
          {__("analytics.reports.downloadMonthlyReport")}
        </span>
      </Button>
    </>
  );
};

export default DownloadMonthlyReportButton;
