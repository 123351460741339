import { useDispatch, useSelector } from "react-redux";
import { Tabs, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import Section from "../Section";

import {
  selectError,
} from "./slice";
import SummaryTab from "./tabs/Summary";
import FollowUpEmailTab from "./tabs/FollowUpEmail";
import { getConfig, selectIsChatWithMeetingEnabled, selectIsFollowUpEnabled } from "../../../session/slice";
import ChatTab from "./tabs/Chat";
import MinuteTab from "./tabs/Minute";
import { selectMeeting, selectMeetingMinute } from "../../slice";
import { BaseMethodologySalesSummaryContainer } from "./tabs/BaseMethodologySalesSummaryContainer";
import { renderMapper } from "./tabs/utils";
import { useTranslation } from "react-i18next";
import { openDealSection } from "../../slice";

const Ai = () => {
  const meeting = useSelector(selectMeeting);
  const { t } = useTranslation();

  const initialTabs = useMemo(() => {
    return [
      {
        label: t('meetings.details.sections.ai.tabs.chat'),
        content: <ChatTab interactionId={meeting.interactionId} />,
      },
      {
        label: t('meetings.details.sections.ai.tabs.methodologySales'),
        content: <BaseMethodologySalesSummaryContainer />,
      },
      {
        label: t('meetings.details.sections.ai.tabs.detailedSummary'),
        content: <SummaryTab />,
      },
    ];
  }, [meeting, t]);

  const requestError = useSelector(selectError);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const isFollowUpEnabled = useSelector(selectIsFollowUpEnabled);
  const isChatWithMeetingEnabled = useSelector(selectIsChatWithMeetingEnabled);
  const [tabs, setTabs] = useState(initialTabs);
  const [tabsRender, setTabsRender] = useState(renderMapper(initialTabs));
  const [followUpEmailFetched, setFollowUpEmailFetched] = useState(false);
  const minute = useSelector(selectMeetingMinute);
  const [activeKey, setActiveKey] = useState('4');

  useEffect(() => {
    if (requestError) {
      messageApi.open({
        type: "error",
        content: t("generic.errors." + requestError),
      });
    }

    dispatch(getConfig());
  }, [requestError, t]);

  useEffect(() => {
    const tabLabels = [...new Set(tabs.map(t => t.label))];

    if (minute && !tabLabels.includes('Minuta')) {
      let newTabs = tabs;

      newTabs.push({
        label: t('meetings.details.sections.ai.tabs.minute.label'),
        content: <MinuteTab />,
      });

      setTabs();
    }
  }, [minute, t]);

  useEffect(() => {
    if ((isFollowUpEnabled || isChatWithMeetingEnabled) && !followUpEmailFetched) {
      let newTabs = tabs;

      if (isFollowUpEnabled && !newTabs.find(i => i.label === "Mail de follow up")) {
        newTabs.push({
          label: t('meetings.details.sections.ai.tabs.followUpEmail'),
          content: <FollowUpEmailTab />,
        });
      }

      setTabs([...new Set(newTabs)]);
      setFollowUpEmailFetched(true);
    }

  }, [isFollowUpEnabled, followUpEmailFetched, isChatWithMeetingEnabled, t]);

  useEffect(() => {
    if (tabs?.length) {
      setTabsRender(renderMapper(tabs));
      dispatch(openDealSection('ai'));
    }
  }, [tabs]);

  return (
    <Section sectionKey="ai" title={<>{t("meetings.details.sections.ai.title")}</>}>
      {contextHolder}
      <Tabs
        activeKey={activeKey}
        onChange={setActiveKey}
        items={tabsRender}
      />
    </Section>
  );
};

export default Ai;
