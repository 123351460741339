import { useDispatch, useSelector } from "react-redux";
import { loadUsers, selectUsers } from "../team/users/slice";
import { useEffect, useMemo, useState } from "react";
import { Modal, Select, Space } from "antd";
import __ from "../../app/i18n";
import { updatePrivacyMeeting } from "../library/slice";
import { useTranslation } from "react-i18next";

const PrivacyModal = ({ meeting, open, onClose }) => {
  const { visibility } = meeting;
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const [fetched, setFetched] = useState();
  const { t } = useTranslation();
  
  const options = useMemo(() => {
    return [
      { value: "ALL", label: t("myNextMeetings.table.columns.privacy.modal.options.all") },
      { value: "MANAGERS", label: t("myNextMeetings.table.columns.privacy.modal.options.onlyManagers") },
      // { value: "ME", label: "Solo yo" }
    ];
  }, [t]);

  const [value, setValue] = useState(options.find(o => o.value === visibility.type)?.value || "ALL");

  useEffect(() => {
    if (!users.length && !fetched) {
      dispatch(loadUsers());
      setFetched(true);
    }
  }, [users, fetched]);


  const selectProps = {
    style: {
      width: "100%",
      marginRight: "5px",
    },
    value,
    options: options,
    onChange: (newValue) => {
      setValue(newValue);
    },
    maxTagCount: "responsive",
    showArrow: true,
  };

  const handleSaveChanges = (data) => {
    const payload = {
      id: meeting.id,
      type: value,
    };

    dispatch(updatePrivacyMeeting(payload));
    onClose();
  };

  return (
    <Modal
      title={t("myNextMeetings.table.columns.privacy.modal.title")}
      centered
      open={open}
      onOk={() => handleSaveChanges()}
      okText={__("library.saveChanges")}
      onCancel={onClose}
    >
      <>
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <div>
            <Select {...selectProps} />
          </div>
          <br />
        </Space>
      </>
    </Modal>
  );
};

export default PrivacyModal;
