import AnalyticBar from "./AnalyticBar";
import AnalyticsTabContent from "./AnalyticsTabContent";
import { Typography } from "antd";
import PeriodGraphs from "./PeriodGraphs";
import { useTranslation } from "react-i18next";

export default function TalkRatioTab({ analyticsState }) {
  const { t } = useTranslation();

  return (
    <AnalyticsTabContent>
      <Typography.Title
        className={"analyticsTabTitle"}
        level={3}
        style={{ marginBottom: "15px" }}
      >
        {t("analytics.dashboard.tabs.activity.children.talkRatio.title")}
      </Typography.Title>
      <AnalyticBar
        avg={analyticsState.talkRatio.avg}
        unit="%"
        userAnalytics={analyticsState.talkRatio.talkRatios}
        prop="avg"
        itemKeyName={"Promedio del usuario"}
      />
      <PeriodGraphs userAnalytics={analyticsState.talkRatio.periods} title={t("analytics.dashboard.tabs.activity.children.talkRatio.evolution")}/>
    </AnalyticsTabContent>
  );
}
