import { Badge, Tooltip } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadNotifications,
  notificationSeen,
  selectCountUnreadNotifications,
  selectNotifications,
} from "./slice";
import NotificationPanel from "./NotificationPanel";
import { CalendarTime, VideoPlus } from "tabler-icons-react";
import NewMeetingModal from "../meetings/NewMeetingModal";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../session/slice";
import { useTranslation } from "react-i18next";

export default function NotificationMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showNotificationPanel, setShowNotificationPanel] = useState(false);
  const [showJoinToMeetingModal, setShowJoinToMeetingModal] = useState(false);
  const notifications = useSelector(selectNotifications);
  const unreadNotifications = useSelector(selectCountUnreadNotifications);
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  // useEffect(() => {
  //   dispatch(loadNotifications());
  // }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     dispatch(loadNotifications());
  //   }, 60 * 1000);

  //   return () => clearInterval(interval);
  // }, []);

  const toggleNotificationPanel = () => {
    // Opening
    if (!showNotificationPanel) {
      dispatch(notificationSeen());
    }

    setShowNotificationPanel(!showNotificationPanel);
  };

  return (
    <>
      <NotificationPanel
        notifications={notifications}
        open={showNotificationPanel}
        onClose={() => setShowNotificationPanel(!showNotificationPanel)}
      />

      {user.role ? (
        <NewMeetingModal
          isModalOpen={showJoinToMeetingModal}
          close={() => setShowJoinToMeetingModal(false)}
        />
      ) : (
        <></>
      )}

      <span onClick={() => toggleNotificationPanel()}>
        <Badge
          count={
            unreadNotifications.length > 0 ? unreadNotifications.length : null
          }
          style={{
            padding: "0 5px",
            top: "-3px",
            fontSize: "10px",
          }}
        >
          <BellOutlined style={{ fontSize: "22px", color: "#fff" }} />
        </Badge>
      </span>

      {user.role ? (
        <>
          <Tooltip title={t("myNextMeetings.label")}>
            <CalendarTime
              style={{
                fontSize: "22px",
                color: "#fff",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
              onClick={() => navigate("/scheduled")}
            />
          </Tooltip>
          <Tooltip title={t("joinMeeting.tooltip")}>
            <VideoPlus
              style={{
                fontSize: "22px",
                color: "#fff",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
              onClick={() => setShowJoinToMeetingModal(true)}
            />
          </Tooltip>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
