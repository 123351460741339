import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../app/api";
import __ from "../../app/i18n";
import { showGlobalErrors } from "../loading/slice";
import moment from "moment";

const initialState = {
  status: "iddle",
  accountCreatedAt: {},
  callDuration: {},
  totalCallVolume: {},
  dailyQtyMeetingsGroupedBySeller: {},
  weeklyQtyMeetingsGroupedBySeller: {},
  talkRatio: {},
  questionRate: {},
  samuScore: {},
  callType: null,
  filters: {
    dateFrom: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
  },
  isComparing: false
};

export const getAnalytics = createAsyncThunk(
  "analytics/getAnalytics",
  async (payload, { dispatch, getState }) => {
    const state = getState();
    const response = await API("getAnalytics", {
      filters: state.analytics.filters,
      callTypes: [state.analytics.callType],
    });
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
    updateCallType: (state, action) => {
      state.callType = action.payload;
    },
    toggleCompare: (state) => {
      state.isComparing = !state.isComparing;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAnalytics.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getAnalytics.fulfilled, (state, action) => {
        const {
          callDuration,
          totalCallVolume,
          talkRatio,
          questionRate,
          samuScore,
          accountCreatedAt,
          dailyQtyMeetingsGroupedBySeller,
          weeklyQtyMeetingsGroupedBySeller,
          dailyCallDurationGroupedBySeller,
          weeklyCallDurationGroupedBySeller,
          monthCallDurationGroupedBySeller
        } = action.payload;

        state.status = "idle";
        state.callDuration = callDuration;
        state.totalCallVolume = totalCallVolume;
        state.talkRatio = talkRatio;
        state.questionRate = questionRate;
        state.samuScore = samuScore;
        state.dailyQtyMeetingsGroupedBySeller = dailyQtyMeetingsGroupedBySeller;
        state.weeklyQtyMeetingsGroupedBySeller =
          weeklyQtyMeetingsGroupedBySeller;
        state.accountCreatedAt = accountCreatedAt;
        state.dailyCallDurationGroupedBySeller = dailyCallDurationGroupedBySeller;
        state.weeklyCallDurationGroupedBySeller = weeklyCallDurationGroupedBySeller;
        state.monthCallDurationGroupedBySeller = monthCallDurationGroupedBySeller;
      });
  },
});

export const selectAnalyticsState = (state) => state.analytics;
export const selectIsComparing = (state) => state.analytics.isComparing;
export const selectSamuScore = (state) => state.analytics.samuScore;
export const selectIsFetching = (state) => state.analytics.status === "loading";
export const selectedCallType = (state) => state.analytics.callType;
export const selectAccountCreatedAt = (state) =>
  state.analytics.accountCreatedAt;
export const selectHasTotalDurationDailyGraph = (state) =>
  !!Object.keys(state.analytics.dailyQtyMeetingsGroupedBySeller).length;

export default analyticsSlice.reducer;

export const { updateFilters, updateCallType, toggleCompare } = analyticsSlice.actions;

export const updateAnalyticsCallTypeFilter =
  (callTypeId) => (dispatch, getState) => {
    dispatch(updateCallType(callTypeId));
    dispatch(getAnalytics());
  };

export const updateAnalyticsFilters = (filters) => (dispatch, getState) => {
  dispatch(updateFilters(filters));
  dispatch(getAnalytics());
};

export const updateUserIdsFilters = (userIds) => (dispatch, getState) => {
  const state = getState();
  const filters = { ...state.analytics.filters, hosts: userIds };

  dispatch(updateFilters(filters));
  dispatch(getAnalytics());
};

export const updateInteractionCallTypesIdsFilters =
  (interactionCallTypesIds) => (dispatch, getState) => {
    const state = getState();
    const filters = {
      ...state.analytics.filters,
      interactionCallTypes: interactionCallTypesIds,
    };

    dispatch(updateFilters(filters));
    dispatch(getAnalytics());
  };

export const updateTeamIdsFilters = (teamIds) => (dispatch, getState) => {
  const state = getState();
  const filters = { ...state.analytics.filters, teams: teamIds };

  dispatch(updateFilters(filters));
  dispatch(getAnalytics());
};

export const updateAnalyticsDateFilters =
  ({ from, to }) =>
  (dispatch, getState) => {
    const state = getState();
    const filters = { ...state.analytics.filters, dateFrom: from, dateTo: to };

    dispatch(updateFilters(filters));
    dispatch(getAnalytics());
  };
