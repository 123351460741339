import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { updateUserLang } from "../../../app/api";
import __ from "../../../app/i18n";
import {
  showGlobalErrors,
  showGlobalSuccess,
  startLoading,
  stopLoading,
} from "../../loading/slice";
import { loadTeams } from "../teams/slice";

const initialState = {
  status: "iddle",
  users: [],
  clients: [],
  speakers: [],
  trackers: [],
  libraries: [],
  activeTab: 'profile',
};

export const loadTrackers = createAsyncThunk("team/getTrackers", async () => {
  const response = await API("getTrackers");

  return response;
});

export const loadUsers = createAsyncThunk("team/getUsers", async () => {
  const response = await API("getHosts");

  return response;
});

export const loadClients = createAsyncThunk("team/clients", async () => {
  const response = await API("getClients");

  return response;
});

export const loadSpeakers = createAsyncThunk("team/speakers", async () => {
  const response = await API("getSpeakers");

  return response;
});

export const createUser = createAsyncThunk(
  "team/createUser",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    let response;
    response = await API("createUser", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    } else {
      dispatch(showGlobalSuccess([__("generic.alerts.userCreated")]));
    }

    dispatch(loadUsers());
    if(payload?.teams){
      dispatch(loadTeams());
    }
    dispatch(stopLoading());
  }
);
export const updateUser = createAsyncThunk(
  "team/updateUser",
  async ({ userId, name, lastName, email, role, lang, teams}, { dispatch }) => {
    const response = await API("updateUser", {
      userId,
      name,
      lastName,
      email,
      role,
      lang,
      teams
    });
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    } else {
      dispatch(showGlobalSuccess([__("generic.alerts.userUpdated")]));
      if (lang) {
        updateUserLang(lang);
      }
      if(teams){
        dispatch(loadTeams());
      }
    }

    return response;
  }
);

export const deleteUser = createAsyncThunk(
  "team/deleteUser",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await API("deleteUser", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    } else {
      dispatch(showGlobalSuccess([__("generic.alerts.userDeleted")]));
    }

    dispatch(loadUsers());
    dispatch(stopLoading());

    return response;
  }
);

export const toggleUserAccess = createAsyncThunk(
  "team/toggleUserAccess",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await API("toggleUserAccess", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(loadUsers());
    dispatch(stopLoading());

    return response;
  }
);

export const resetPassword = createAsyncThunk(
  "team/resetPassword",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await API("resetPassword", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    } else {
      dispatch(showGlobalSuccess([__("generic.alerts.resetedPassword")]));
    }

    dispatch(stopLoading());

    return response;
  }
);

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loadUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadUsers.fulfilled, (state, action) => {
        state.status = "idle";
        state.users = action.payload;
      })
      .addCase(loadClients.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadClients.fulfilled, (state, action) => {
        state.status = "idle";
        state.clients = action.payload;
      })
      .addCase(loadSpeakers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadSpeakers.fulfilled, (state, action) => {
        state.status = "idle";
        state.speakers = action.payload;
      })
      .addCase(loadTrackers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadTrackers.fulfilled, (state, action) => {
        state.status = "idle";
        state.trackers = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.users = action.payload;
      });
  },
});

// export const selectMeeting = (state) => state.meeting.meeting;
export const selectIsFetching = (state) => state.team.status === "loading";
export const selectUsers = (state) => state.team.users;
export const selectClients = (state) => state.team.clients;
export const selectSpeakers = (state) => state.team.speakers;
export const selectTrackers = (state) => state.team.trackers;
// export const selectOpenSection = (state) => state.meeting.openSection;
// export const selectCC = (state) => state.meeting.showCC;
export const selectActiveTab = (state) => state.team.activeTab;
export const { setActiveTab } = teamSlice.actions;

export default teamSlice.reducer;
