import { useDispatch } from "react-redux";
import { Select } from "antd";
import { updateDateFilter, dateFilterMap } from "../slice";
import styles from "../coaching.module.css";

export default function DateFilter() {
  const dispatch = useDispatch();

  const handleChange = (selectedDate) => {
    let selected = dateFilterMap.last7Days;
    if (selectedDate) {
      selected = dateFilterMap[selectedDate];
    }
    const { from, to } = selected;
    dispatch(
      updateDateFilter({
        from,
        to,
      })
    );
  };

  const options = [
    { value: "last7Days", label: "Últimos 7 días" },
    { value: "lastWeek", label: "Semana anterior" },
    { value: "last15Days", label: "Últimos 15 días" },
    { value: "last30Days", label: "Últimos 30 días" },
    { value: "currentYear", label: "Año actual" },
    { value: "lastYear", label: "Año anterior" },
  ];

  return (
    <div className={styles.filterContainer}>
      <span className={styles.filterTitle}>Fecha</span>
      <Select
        allowClear
        options={options}
        onChange={handleChange}
        defaultValue="last7Days"
        className={styles.filterSelect}
        placeholder="Seleccione período"
      />
    </div>
  );
}
