import { useState } from 'react';
import { Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateConferenceIdFilter, selectedConferenceId } from '../slice';
import __ from '../../../../app/i18n';


const processConferenceLink = (link) => {
  if (!link) return null;
  
  const googleMeetRegex = /meet\.google\.com\/([\w-]+)/;
  const googleMatch = link.match(googleMeetRegex);
  if (googleMatch) return googleMatch[1];

  if (link.includes('teams.microsoft.com')) return link;

  const hubspotRegex = /hubspot\.com\/calls\/\d+\/review\/(\d+)/;
  const hubspotMatch = link.match(hubspotRegex);
  if (hubspotMatch) return hubspotMatch[1];

  return link;
};

const ConferenceIdFilter = () => {
  const dispatch = useDispatch();
  const conferenceId = useSelector(selectedConferenceId);
  const [conferenceIdValue, setConferenceIdValue] = useState(conferenceId);
  

  const handleClick = () => {
    dispatch(updateConferenceIdFilter(conferenceIdValue));
  };
  const handleChange = (e) => {
    setConferenceIdValue(processConferenceLink(e.target.value));
  };
  return (
    <div style={{ padding: '10px' }}>
      <Input
        placeholder={__('meetings.filters.enterMeetingLink')}
        value={conferenceIdValue || ''}
        onChange={handleChange}
        allowClear
      />
      <Button
        type="primary"
        style={{ marginTop: "5px", width: "100%" }}
        onClick={handleClick}
      >
        {__("meetings.filters.apply")}
      </Button>
    </div>
  );
};

export default ConferenceIdFilter;