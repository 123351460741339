import styles from "./styles.module.css";
import { Comment, Tooltip, Typography } from "antd";
import moment from "moment";
import { getTimeInTimezone } from "./Message";
import { IconFile } from "@tabler/icons-react";
import __ from "../../app/i18n";

export default function ImagePreview({ comment }) {
  return (
      <Comment
      className={
        styles.chatComment +
        " conversation-message-" +
        String(comment.type).toLowerCase()
      }
      key={`comment:${comment.id}`}
      dataId={`comment:${comment.id}`}
      author={<a>{comment.name}</a>}
      content={
        <Tooltip title={__("conversation.chat.cannotDownloadFile")}>
          <p className={styles.messageChatContent}>
          <div className={styles.fileContainer}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconFile stroke={2} />
              <Typography.Text strong={true} style={{ marginLeft: "10px" }}>
                {comment.filename}
              </Typography.Text>
            </div>
          </div>
          <span className={styles.messageChatContentTime}>
            {comment.timezone
              ? getTimeInTimezone(comment.date, comment.timezone)
              : moment(comment.date).format("HH:mm")}
          </span>
        </p>
        </Tooltip>
      }
    />
  );
}
