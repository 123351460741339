import CallTypeFilter from "./CallTypeFilter";
import DateFilter from "./DateFilter";
import TeamFilter from "./TeamFilter";
import styles from "../coaching.module.css";
import DownloadMonthlyReport from "../DownloadMonthlyReport";
import { Col, Row } from "antd";

export default function CoachingFilters() {
  return (
    <Row align="middle">
      <Col xs={24} sm={24} md={24} lg={9}>
        <DownloadMonthlyReport />
      </Col>
      <Col xs={24} sm={24} md={15} className={styles.alignLeft}>
        <div className={styles.filtersContainer}>
          <CallTypeFilter />
          <DateFilter />
          <TeamFilter />
        </div>
      </Col>
    </Row>
  );
}
