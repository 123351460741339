import { Card, Form, Input, Select, message } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import __ from "../../../app/i18n";
import { OptionSelect } from "./OptionSelect";
import { useTranslation } from "react-i18next";

const placeholders = {
  number: {
    description:
      "Es el tamaño del equipo de ventas mencionado en la transcripción por el cliente.",
    name: "Size",
  },
  string: {
    name: "Provider",
    description:
      "Es el proveedor que utiliza el cliente para las llamadas mencionado en la transcripción por el cliente. Si no lo encontras, utiliza el valor 'No Mencionado'.",
    examples: ["Hubspot"],
  },
  uniqueOption: {
    name: "CRM",
    description:
      "Es el CRM mencionado en la transcripción utilizado por el cliente.",
    values: ["Hubspot", "Salesforce", "Pipedrive"],
  },
  array: {
    name: "Customer Platform",
    description: "¿Que plataforma utiliza para interactuar con sus clientes?",
    values: ["Hubspot Calling", "Whatsapp", "Google Meet", "Microsoft Teams"],
  },
  boolean: {
    name: "Interest Product",
    description: "¿El cliente mostro interés en el producto?",
  },
};

const types = {
  number: __("apps.extractor.property.types.number"),
  string: __("apps.extractor.property.types.string"),
  uniqueOption: __("apps.extractor.property.types.uniqueOption"),
  array: __("apps.extractor.property.types.multipleOption"),
  boolean: __("apps.extractor.property.types.boolean"),
};

function Property(props) {
  const [name, setName] = useState(props.name);
  const [description, setDescription] = useState(props.description);
  const [type, setType] = useState(props.type);
  const [values, setValues] = useState(props.values || []);
  const [placeHolder, setPlaceholder] = useState(placeholders["uniqueOption"]);
  const [examples, setExamples] = useState(props.examples || []);
  const [fieldErrors, setFieldErrors] = useState({});
  const { t } = useTranslation();

  const validateFields = (values, type, name, description, examples) => {
    const errors = {};
    if (!type) errors.type = t("apps.extractor.property.errors.type");
    if (!name) errors.name = t("apps.extractor.property.errors.name");
    if (!description)
      errors.description = t("apps.extractor.property.errors.description");
    if ((type === "uniqueOption" || type === "array") && values.length === 0) {
      errors.values = t("apps.extractor.property.errors.values");
    }

    return errors;
  };

  useEffect(() => {
    const errors = validateFields(values, type, name, description, examples);
    setFieldErrors(errors);

    props.onUpdateProperty({
      id: props.id,
      index: props.index,
      examples,
      name,
      description,
      type,
      values,
      errors,
    });
  }, [type, name, description, values, examples]);

  const validateAndAddOption = (newOption) => {
    const normalizedOption = newOption?.trim();
    
    if (!normalizedOption) {
      message.warning(t("apps.extractor.property.errors.emptyValue"));
      return;
    }

    if (values.some(value => value.toLowerCase() === normalizedOption.toLowerCase())) {
      message.warning(t("apps.extractor.property.errors.duplicate"));
      return;
    }

    setValues([...values, normalizedOption]);
  };

  return (
    <Card
      size="small"
      title={name.toUpperCase()}
      key={props.index}
      style={{ marginBottom: "15px", maxWidth: "700px" }}
      extra={<CloseOutlined onClick={() => props.onRemove(props.id)} />}
      headStyle={{ background: "#eee" }}
    >
      <Form layout="vertical">
        <Form.Item
          label={t("apps.extractor.property.fields.type")}
          validateStatus={fieldErrors.type ? "error" : ""}
          help={fieldErrors.type}
        >
          <Select
            style={{ width: "200px" }}
            value={type}
            onChange={(value) => {
              setType(value);
              setPlaceholder(placeholders[value]);
              if (value !== "uniqueOption" || value !== "array") {
                setValues([]);
              }
            }}
          >
            {Object.entries(types).map(([key, label]) => (
              <Select.Option key={key} value={key}>
                {label.toUpperCase()}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("apps.extractor.property.fields.name")}
          validateStatus={fieldErrors.name ? "error" : ""}
          help={fieldErrors.name}
        >
          <Input
            key="name"
            value={name}
            placeholder={placeHolder.name}
            onChange={(e) => setName(e.target.value.replace(/"/g, ''))}
          />
        </Form.Item>

        <Form.Item
          label={t("apps.extractor.property.fields.description")}
          validateStatus={fieldErrors.description ? "error" : ""}
          help={fieldErrors.description}
        >
          <Input.TextArea
            rows={6}
            key={[props.index, "desc"].join("#")}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            style={{ display: "block", width: "100%" }}
            placeholder={placeHolder.description}
          />
        </Form.Item>

        {(type === "uniqueOption" || type === "array") && (
          <Form.Item
            label="Posibles valores"
            validateStatus={fieldErrors.values ? "error" : ""}
            help={fieldErrors.values}
          >
            <OptionSelect
              options={values}
              onRemove={(index) =>
                setValues(values.filter((_, i) => i !== index))
              }
              onNew={validateAndAddOption}
            />
          </Form.Item>
        )}

        {type === "string" && (
          <Form.Item
            label="Ejemplos"
          >
            <OptionSelect
              options={examples}
              onRemove={(index) =>
                setExamples(examples.filter((_, i) => i !== index))
              }
              onNew={(newExample) => setExamples([...examples, newExample])}
            />
          </Form.Item>
        )}
      </Form>
    </Card>
  );
}

export default Property;
