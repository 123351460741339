import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { loadInteractionCallTypes, selectInteractionCallTypes } from "../../team/interactionCallType/slice";
import { updateCallTypeFilter } from "../slice";
import styles from '../coaching.module.css';

export default function CallTypeFilter() {
  const dispatch = useDispatch();
  const callTypes = useSelector(selectInteractionCallTypes);
  useEffect(() => {
    dispatch(loadInteractionCallTypes());
  }, []);

  const handleChange = (callTypeId) => {
    dispatch(updateCallTypeFilter(callTypeId));
  };

  const options = callTypes.map(callType => ({
    label: callType.name,
    value: callType._id
  }));

  return (
    <div className={styles.filterContainer}>
      <span className={styles.filterTitle}>Tipo de llamada</span>
      <Select
        allowClear
        options={options}
        onChange={handleChange}
        className={styles.filterSelect}
        placeholder="Seleccione tipo de llamada"
      />
    </div>
  );
} 