import { Drawer, Button, Form, Input, Checkbox, Select, Tag, Alert} from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "./slice";
import { selectTeams } from "../teams/slice";
import { removeAccents } from "../../../app/utils";
import { useTranslation } from "react-i18next";

export default function NewTeamUser({ open, onClose, connections, seats }) {
  const dispatch = useDispatch();
  const teams = useSelector(selectTeams);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false);
  const [selectAllTeams, setSelectAllTeams] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const { t } = useTranslation();
  const hasReachedLicenseLimit = connections >= seats;
  const handleSelectAllTeamsChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAllTeams(isChecked);
    const newTeams = isChecked ? teams.map(team => team._id) : [];
    setSelectedTeams(newTeams);
  };

  const handleTeamsChange = (value) => {
    setSelectedTeams(value);
    setSelectAllTeams(value.length === teams.length);
  };

  const handleNewUser = (values) => {
    dispatch(createUser({ 
      ...values,
      sendWelcomeEmail,
      teams: selectAllTeams ? teams.map(team => team._id) : selectedTeams,
      role: Number(values.role)
    }));
  };

  return (
    <Drawer
      title={t("team.list.newUser")}
      placement={"right"}
      width={500}
      onClose={() => onClose()}
      open={open}
      getContainer={true}
    >
      {hasReachedLicenseLimit && (
          <Alert
            message={t("team.licenseLimit.warning")}
            description={t("team.licenseLimit.description")}
            type="warning"
            showIcon
            style={{ marginBottom: "16px" }}
          />
        )}
      <Form
        name="basic"
        layout="vertical"
        onFinish={handleNewUser}
        autoComplete="off"
        initialValues={{
          role: 0,
        }}
      >
        <Form.Item
          name="name"
          label={t("generic.fields.name")}
          rules={[
            {
              required: true,
              message: t("generic.required.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={t("generic.fields.lastName")}
          rules={[
            {
              required: true,
              message: t("generic.required.lastName"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: t("generic.required.email"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("generic.fields.role")} name="role">
          <Select>
            <Select.Option value={0}>{t("roles.collaborator")}</Select.Option>
            <Select.Option disabled={hasReachedLicenseLimit} value={1}>{t("roles.seller")}</Select.Option>
            <Select.Option value={2}>{t("roles.manager")}</Select.Option>
           </Select>
        </Form.Item>
        <Form.Item style={{ marginBottom: "2px" }}>
          <Checkbox
            checked={selectAllTeams}
            onChange={handleSelectAllTeamsChange}
          >
            {t("team.newTeam.selectAllTeams")}
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="teams"
          label={t("team.teams")}
        >
          <Select
            mode="multiple"
            disabled={selectAllTeams}
            value={selectedTeams}
            onChange={handleTeamsChange}
            style={{ width: '100%' }}
            optionLabelProp="label"
            filterOption={(typed, item) => {
              const contains = String(item.label)
                .toUpperCase()
                .startsWith(String(removeAccents(typed)).toUpperCase());
              return contains;
            }}
          >
            {teams.map(team => (
              <Select.Option 
                key={team._id} 
                value={team._id}
                label={team.name}
              >
                <Tag color={team.color || 'blue'}>{team.name}</Tag>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("generic.fields.sendWelcomeEmail")}
          name="sendWelcomeEmail"
        >
          <Checkbox name="sendWelcomeEmail" onChange={(e) => setSendWelcomeEmail(e.target.checked)}/>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("generic.fields.createUser")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
