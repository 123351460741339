import styles from "./styles.module.css";
import { Comment } from "antd";
import moment from "moment";

export function getTimeInTimezone(date, timeZone) {
  return new Date(date).toLocaleTimeString("en-US", {
    timeZone: timeZone,
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
}

export default function Message({ comment }) {
  return (
    <Comment
      className={
        styles.chatComment +
        " conversation-message-" +
        String(comment.type).toLowerCase()
      }
      key={`comment:${comment.id}`}
      dataId={`comment:${comment.id}`}
      author={<a>{comment.name}</a>}
      content={
        <div className={styles.messageChatContent + " " + styles.textComment}>
          <span>{comment.text}</span>
          <span
            style={{ alignSelf: "end", marginLeft: "5px" }}
            className={styles.messageChatContentTime}
          >
            {comment.timezone
              ? getTimeInTimezone(comment.date, comment.timezone)
              : moment(comment.date).format("HH:mm")}
          </span>
        </div>
      }
    />
  );
}
