import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../app/api";
import { showGlobalErrors } from "../../loading/slice";

const initialState = {
  status: "iddle",
  accountCreatedAt: {},
  callDuration: {},
  totalCallVolume: {},
  dailyQtyMeetingsGroupedBySeller: {},
  weeklyQtyMeetingsGroupedBySeller: {},
  talkRatio: {},
  questionRate: {},
  samuScore: {},
  callType: null,
  filters: {},
};

export const getAnalytics = createAsyncThunk(
  "compareAnalytics/getAnalytics",
  async (payload, { dispatch, getState }) => {
    const state = getState();
    const response = await API("getAnalytics", {
      filters: state.compareAnalytics.filters,
      callTypes: [state.compareAnalytics.callType],
    });
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

export const analyticsSlice = createSlice({
  name: "compareAnalytics",
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
    updateCallType: (state, action) => {
      state.callType = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAnalytics.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getAnalytics.fulfilled, (state, action) => {
        const {
          callDuration,
          totalCallVolume,
          talkRatio,
          questionRate,
          samuScore,
          accountCreatedAt,
          dailyQtyMeetingsGroupedBySeller,
          weeklyQtyMeetingsGroupedBySeller,
          dailyCallDurationGroupedBySeller,
          weeklyCallDurationGroupedBySeller,
          monthCallDurationGroupedBySeller
        } = action.payload;

        state.status = "idle";
        state.callDuration = callDuration;
        state.totalCallVolume = totalCallVolume;
        state.talkRatio = talkRatio;
        state.questionRate = questionRate;
        state.samuScore = samuScore;
        state.dailyQtyMeetingsGroupedBySeller = dailyQtyMeetingsGroupedBySeller;
        state.weeklyQtyMeetingsGroupedBySeller =
          weeklyQtyMeetingsGroupedBySeller;
        state.accountCreatedAt = accountCreatedAt;
        state.dailyCallDurationGroupedBySeller = dailyCallDurationGroupedBySeller;
        state.weeklyCallDurationGroupedBySeller = weeklyCallDurationGroupedBySeller;
        state.monthCallDurationGroupedBySeller = monthCallDurationGroupedBySeller;
      });
  },
});

export const selectIsComparing = (state) => state.analytics.isComparing;
export const selectAnalyticsState = (state) => state.compareAnalytics;
export const selectSamuScore = (state) => state.compareAnalytics.samuScore;
export const selectIsFetching = (state) => state.compareAnalytics.status === "loading";
export const selectedCallType = (state) => state.compareAnalytics.callType;
export const selectAccountCreatedAt = (state) =>
  state.compareAnalytics.accountCreatedAt;
export const selectHasTotalDurationDailyGraph = (state) =>
  !!Object.keys(state.compareAnalytics.dailyQtyMeetingsGroupedBySeller).length;

export default analyticsSlice.reducer;

export const { updateFilters, updateCallType } = analyticsSlice.actions;

export const updateAnalyticsCallTypeFilter =
  (callTypeId) => (dispatch, getState) => {
    dispatch(updateCallType(callTypeId));
    dispatch(getAnalytics());
  };

export const updateAnalyticsFilters = (filters) => (dispatch, getState) => {
  dispatch(updateFilters(filters));
  dispatch(getAnalytics());
};

export const updateUserIdsFilters = (userIds) => (dispatch, getState) => {
  const state = getState();
  const filters = { ...state.compareAnalytics.filters, hosts: userIds };

  dispatch(updateFilters(filters));
  dispatch(getAnalytics());
};

export const updateInteractionCallTypesIdsFilters = (interactionCallTypesIds) => (dispatch, getState) => {
  const state = getState();
  const filters = { ...state.compareAnalytics.filters, interactionCallTypes: interactionCallTypesIds };

  dispatch(updateFilters(filters));
  dispatch(getAnalytics());
};

export const updateAnalyticsDateFilters =
  ({ from, to }) =>
  (dispatch, getState) => {
    const state = getState();
    const filters = { ...state.compareAnalytics.filters, dateFrom: from, dateTo: to };

    dispatch(updateFilters(filters));
    dispatch(getAnalytics());
  };
