import {
  Breadcrumb,
  Layout,
  Pagination,
  Row,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectApps,
  getAppForm,
  selectApp,
  saveAppSettings,
  selectFetchedApp,
} from "./slice";

import Loading from "../loading/Index";
import __ from "../../app/i18n";
import { useParams } from "react-router-dom";
import HubspotApp from './Hubspot/App';
import WebhookApp from './Webhook/App';
import ExtractorApp from './Extractor/App';
import MarketplaceApp from './Marketplace/App';
import PostMeetingEmailApp from './PostMeetingEmail/App';
import DailyManagerReportApp from './DailyManagerReport/App';
import CustomScoreApp from './CustomScore/App';
import { useTranslation } from "react-i18next";

const New = () => {
  const apps = useSelector(selectApps);
  const dispatch = useDispatch();
  const fetched = useSelector(selectFetchedApp);
  const { appTypeId } = useParams("id");
  const app = useSelector(selectApp);
  const { t } = useTranslation();

  const getAppByTypeId = (typeId, props) => {
    const map = {
      "65b05066b9d8ab7dca53b669": <HubspotApp {...props}/>,
      "65b04d8fb9d8ab7dca53b65a": <WebhookApp {...props}/>,
      "65b0500ab9d8ab7dca53b667": <ExtractorApp {...props}/>,
      "660c489f3db9a56dd10c6442": <PostMeetingEmailApp {...props}/>,
      "6612ff3a023c2d597ba7466d": <DailyManagerReportApp {...props}/>,
      "673f72d0f9d3d2e46c8c6228": <CustomScoreApp {...props}/>,
    }

    const component = map[typeId];
    if (!component) {
      return <MarketplaceApp {...props} />;
    }

    return component;
  };


  useEffect(() => {
    const p = () => {
      dispatch(getAppForm({ appTypeId }));
    }
    
    return p();
  }, []);

  if (!fetched) {
    return <Loading />;
  }

  const handleOnSave = (settings) => {
    dispatch(saveAppSettings({ appId: app.appId, settings }));
  }

  const component = getAppByTypeId(appTypeId, { app, handleOnSave });

  return (
    <Content style={{ padding: "0 25px" }}>
      <Layout
        className={"site-layout-background main-layout-container"}
        style={{ padding: "24px 0" }}
      >
        <Content style={{ padding: "0 24px", minHeight: 280 }}>
          <Breadcrumb style={{ margin: "15px 0" }}>
            <Breadcrumb.Item>{t("apps.breadcrumbs.home")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="/apps">{t("apps.label")}</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              { t(app.name) }
            </Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            { component }
          </Row>
          <br />
          <Pagination
            hideOnSinglePage
            onChange={() => {}}
            defaultCurrent={1}
            pageSize={20}
            total={apps.length}
          />
        </Content>
      </Layout>
    </Content>
  );
};

export default New;
