import { getTimestampOfMessageToPlay } from "../../app/utils";
import styles from "./MediaControls.module.css";
import { Tooltip } from 'antd';
import __ from "../../app/i18n";

export default function SpeakerNavigation({ meeting, videoRef }) {
  const findNextSpeakerTime = () => {
    if (!meeting.transcription || !videoRef.current) return;

    const currentTime = videoRef.current.currentTime;
    let current;
    let next;
    for (let i = 0; i < meeting.transcription.length; i++) {
      let e = { ...meeting.transcription[i] };

      if (['MICROSOFT', 'GOOGLE'].includes(meeting.provider)) {
        e.time = getTimestampOfMessageToPlay(meeting, e);
      } else {
        e.time = e.startAt;
      }

      if (!current && e.time > currentTime) {
        next = e;
        break;
      }
      if (!current && e.time <= currentTime) {
        current = e;
      }
      if (e.time <= currentTime && e.who !== current.who) {
        current = e;
      }
      if (e.time > currentTime && e.who !== current.who) {
        next = e;
        break;
      }
    }

    videoRef.current.currentTime = next.time;
  };
  // el unico icon no me deja importar de tabler-react
  return (
    <Tooltip onClick={findNextSpeakerTime} className={styles.speakerIcon} title={__('meetings.details.sections.transcription.nextSpeaker')} placement="top">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="40px"  height="40px"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-message-circle-user"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 22a2 2 0 0 0 -2 -2h-2a2 2 0 0 0 -2 2" /><path d="M12.454 19.97a9.9 9.9 0 0 1 -4.754 -.97l-4.7 1l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c1.667 1.423 2.596 3.294 2.747 5.216" /></svg>
    </Tooltip>
  );
} 